import { useLoaderData } from '@tanstack/react-router';
import parse from 'html-react-parser';

import { FormuleChoice } from '@/types/types';

import { SectionSubtitle, SectionTitle } from './titles';

export const PaymentExplanation = ({ data }: { data: FormuleChoice | null }) => {
  const { content } = useLoaderData({
    from: '/steps',
  });

  if (!data) return <aside className="mx-4 hidden w-1/4 lg:block"></aside>;

  return (
    <aside className="mx-4 hidden w-1/4 lg:block">
      <SectionTitle title="Mon échéancier" className="mx-2 py-0" />
      <SectionSubtitle title={`PAIEMENT : ${data.cost} €`} className="mx-2 py-0" />
      <div className="prose prose-sm prose-stone mx-2 my-4 space-y-4 break-words py-0 text-sm font-medium text-black/60 prose-h2:my-0 prose-h2:py-0 prose-p:m-0 prose-p:p-0 prose-blockquote:my-0 prose-blockquote:py-0 prose-ol:my-0 prose-ol:py-0 prose-ul:my-0 prose-ul:py-0 prose-li:my-0 prose-li:py-0">
        {parse(content ?? '')}
      </div>
    </aside>
  );
};
