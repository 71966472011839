import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useLoaderData, useNavigate } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { DatePicker } from '@/components/date-picker';
import { SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { stepTwoSubmit } from '@/data/form-handlers';
import { referentSchema } from '@/data/forms-schema';
import { SchoolRecord } from '@/types/types';

export const ReferentInformations = () => {
  const navigate = useNavigate();

  const {
    pendingInscription,
  }: {
    pendingInscription: SchoolRecord | null;
    // @ts-expect-error --  Les types de la propriété strict sont incompatibles.(Impossible d'assigner le type false au type true)
  } = useLoaderData({
    from: '/steps',
    strict: false,
  });

  const form = useForm<z.infer<typeof referentSchema>>({
    resolver: zodResolver(referentSchema),
    defaultValues: {
      civility: pendingInscription?.referent?.civility ?? 'M',
      referentFirstName: pendingInscription?.referent?.firstname ?? '',
      referentLastName: pendingInscription?.referent?.lastname ?? '',
      referentBirthDate: pendingInscription?.referent?.birthdate
        ? new Date(pendingInscription?.referent?.birthdate)
        : new Date(),
      referentPlaceOfBirth: pendingInscription?.referent?.placeOfBirth ?? '',
      referentAddress: pendingInscription?.referent?.address?.street ?? '',
      referentPostalCode: pendingInscription?.referent?.address?.postalCode ?? '',
      referentCity: pendingInscription?.referent?.address?.city ?? '',
      referentCountry: pendingInscription?.referent?.address?.country ?? '',
      referentEmail: pendingInscription?.referent?.contact?.email ?? '',
      referentMobilePhone: pendingInscription?.referent?.contact?.mobilePhone ?? '',
      referentHomePhone: pendingInscription?.referent?.contact?.landlinePhone ?? '',
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const response = await stepTwoSubmit(data);

          //Wait 3s before redirecting to the next step
          await new Promise((resolve) => setTimeout(resolve, 3000));

          if (response) {
            navigate({
              to: '/steps/three',
            });
          } else {
            console.error('Error adding document');
          }
        })}
        className="h-fit w-full bg-white px-7 py-4 text-black/60 lg:w-2/3"
      >
        <SectionTitle title="Coordonnées du responsable légal" />
        <FormDescription>Cette personne sera prévenue en cas d'urgence.</FormDescription>
        <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
          <FormField
            control={form.control}
            name="civility"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">Civilité</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue focus:ring-0">
                      <SelectValue placeholder="Selectionnez une civilité" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="M">Monsieur</SelectItem>
                    <SelectItem value="F">Madame</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referentFirstName"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">Prénom</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referentLastName"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">Nom de famille</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referentBirthDate"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">Date de naissance</FormLabel>
                <DatePicker date={field.value} setDate={field.onChange} />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referentPlaceOfBirth"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">Lieu de naissance</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <SectionTitle title="Adresse" />
        <FormField
          control={form.control}
          name="referentAddress"
          render={({ field }) => (
            <FormItem className="mb-2 focus-within:text-previsionblue">
              <FormLabel className="text-base">Adresse (numéro, rue)</FormLabel>
              <Input
                {...field}
                className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
          <FormField
            control={form.control}
            name="referentPostalCode"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">Code postal</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referentCity"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">Ville</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referentCountry"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">Pays</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <SectionTitle title="Contact" />
        <FormField
          control={form.control}
          name="referentEmail"
          render={({ field }) => (
            <FormItem className="mb-2 focus-within:text-previsionblue">
              <FormLabel className="text-base">Adresse mail</FormLabel>
              <Input
                {...field}
                className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
              />
              <FormDescription className="text-xs">
                Attention, le courriel doit être valide afin de vous envoyer les
                informations administratives.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
          <FormField
            control={form.control}
            name="referentMobilePhone"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">Numéro de téléphone portable</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="referentHomePhone"
            render={({ field }) => (
              <FormItem className="mb-0 mt-auto focus-within:text-previsionblue">
                <FormLabel className="text-base">Numéro de téléphone fixe</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <div className="flex justify-between">
          <Link to={`/steps/one`}>
            <Button type="button" variant="outlinedBlue" className="w-32">
              Précédent
            </Button>
          </Link>
          <Button
            type="submit"
            variant="squaredBlue"
            disabled={form.formState.isSubmitting}
            className="w-32"
          >
            {form.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Suivant'
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};
