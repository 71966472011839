import { useLoaderData, useMatchRoute } from '@tanstack/react-router';
import { FileDown } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { inscriptionsColumn } from '@/components/data-table/columns';
import { InscriptionsDataTable } from '@/components/data-table/table';
import { SectionTitle } from '@/components/titles';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { InscriptionRow } from '@/types/types';
import { downloadGeneratedCSV } from '@/utils/downloadGeneratedCSV';

interface filterType {
  recordStatus: {
    pending: boolean;
    submitted: boolean;
    cancelled: boolean;
    archived: boolean;
    deleted: boolean;
  };
  scholarYear: string;
  formuleType: string;
  onlyValidated: boolean;
  onlyRefunded: boolean;
  selectedFields: {
    student: boolean;
    referent: boolean;
    pastScolarity: boolean;
    formule: boolean;
    payment: boolean;
  };
}

export const InscriptionsTablePage = ({
  from,
  title,
}: {
  from: string;
  title: string;
}) => {
  const matchRoute = useMatchRoute();
  const isPaidPage = matchRoute({ to: '/admin/inscriptions/done' }) ? true : false;

  const [dialogTrigger, setDialogTrigger] = useState(false);
  const [exportStyle, setExportStyle] = useState<'ALL' | 'personalized'>('ALL');
  const [exportFilters, setExportFilters] = useState<filterType>({
    recordStatus: {
      pending: true,
      submitted: true,
      cancelled: true,
      archived: true,
      deleted: false,
    },
    scholarYear: 'all',
    formuleType: 'all',
    onlyValidated: false,
    onlyRefunded: false,
    selectedFields: {
      student: true,
      referent: true,
      pastScolarity: true,
      formule: true,
      payment: true,
    },
  });

  const {
    data,
    yearList,
    formuleList,
  }: { data: InscriptionRow[]; yearList: string[]; formuleList: string[] } =
    useLoaderData({
      // @ts-expect-error -- Type is coming from router, find how to type it
      from,
    });

  const sortedId = isPaidPage ? 'paidAt' : 'createdAt';

  return (
    <section className="min-h-[calc(100vh-5rem)] w-full bg-previsiongrey p-2 md:w-[calc(100%-20rem)]">
      <SectionTitle title={title} />
      <InscriptionsDataTable
        columns={inscriptionsColumn}
        data={data}
        yearList={yearList}
        formuleList={formuleList}
        recordValidatedSelector={isPaidPage ? true : false}
        sortedBy={[
          {
            id: sortedId,
            desc: true,
          },
        ]}
      />

      <div className="fixed bottom-[5%] right-[1%] hidden md:block">
        <Button
          className="h-12 w-12 rounded-full bg-previsionorange p-2 text-white transition-all  hover:bg-previsionorange/60"
          title="Télécharger le CSV"
          onClick={() => setDialogTrigger(true)}
        >
          <FileDown size={42} />
        </Button>
      </div>
      <div className="w-full p-4 md:hidden">
        <Button
          className="w-full bg-previsionorange text-white  transition-all hover:bg-previsionorange/60"
          title="Télécharger le CSV"
          onClick={() => setDialogTrigger(true)}
        >
          <FileDown size={30} className="m-4" /> <p>Télécharger le CSV</p>
        </Button>
      </div>

      <Dialog
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setDialogTrigger(false);
          } else {
            setDialogTrigger(true);
          }
        }}
        open={dialogTrigger}
      >
        <DialogContent className="max-h-[95vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Exporter les données en CSV</DialogTitle>
          </DialogHeader>
          <h3 className="text-sm font-semibold italic leading-none tracking-tight">
            Type d'inscriptions à exporter
          </h3>
          <section className="mx-2 grid grid-cols-2 gap-2 md:space-y-1">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="pendingStatus"
                defaultChecked={true}
                checked={exportFilters.recordStatus.pending}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    recordStatus: {
                      ...exportFilters.recordStatus,
                      pending: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="pendingStatus"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                inscriptions en cours
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="submittedStatus"
                defaultChecked={true}
                checked={exportFilters.recordStatus.submitted}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    recordStatus: {
                      ...exportFilters.recordStatus,
                      submitted: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="submittedStatus"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                inscriptions payées
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="cancelledStatus"
                defaultChecked={true}
                checked={exportFilters.recordStatus.cancelled}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    recordStatus: {
                      ...exportFilters.recordStatus,
                      cancelled: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="cancelledStatus"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                inscriptions annulées
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="archivedStatus"
                defaultChecked={true}
                checked={exportFilters.recordStatus.archived}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    recordStatus: {
                      ...exportFilters.recordStatus,
                      archived: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="archivedStatus"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                inscriptions archivées
              </label>
            </div>
            {/* <div className="flex items-center space-x-2">
              <Checkbox
                id="deletedStatus"
                defaultChecked={true}
                checked={exportFilters.recordStatus.deleted}
                onCheckedChange={(checked: boolean) => {
                  setExportFilters({
                    ...exportFilters,
                    recordStatus: {
                      ...exportFilters.recordStatus,
                      deleted: checked,
                    },
                  });
                }}
              />
              <label
                htmlFor="deletedStatus"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                inscriptions supprimées définitivement
              </label>
            </div> */}
          </section>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="py-2 text-sm font-semibold italic leading-none tracking-tight hover:no-underline">
                Customisation de l'export
              </AccordionTrigger>
              <AccordionContent className="mx-auto w-11/12 py-2">
                <section className="md:space-y-2">
                  <div className="flex w-full flex-col lg:flex-row">
                    <Select
                      defaultValue="ALL"
                      value={exportStyle}
                      onValueChange={(value: 'ALL' | 'personalized') => {
                        if (value === 'ALL') {
                          setExportFilters({
                            recordStatus: exportFilters.recordStatus,
                            scholarYear: 'all',
                            formuleType: 'all',
                            onlyValidated: false,
                            onlyRefunded: false,
                            selectedFields: {
                              student: true,
                              referent: true,
                              pastScolarity: true,
                              formule: true,
                              payment: true,
                            },
                          });
                        }

                        setExportStyle(value);
                      }}
                    >
                      <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
                        <SelectValue placeholder="Selectionner une année scolaire" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="ALL">Toutes les données</SelectItem>
                        <SelectItem value="personalized">Personnalisé</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex w-full flex-col lg:flex-row">
                    <Select
                      disabled={exportStyle === 'ALL'}
                      value={exportFilters.scholarYear}
                      onValueChange={(value) => {
                        setExportFilters({ ...exportFilters, scholarYear: value });
                      }}
                    >
                      <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
                        <SelectValue placeholder="Selectionner une année scolaire" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">Toutes les années scolaires</SelectItem>
                        {yearList.map((year) => (
                          <SelectItem key={year} value={year}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Select
                      disabled={exportStyle === 'ALL'}
                      value={exportFilters.formuleType}
                      onValueChange={(value) => {
                        setExportFilters({ ...exportFilters, formuleType: value });
                      }}
                    >
                      <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
                        <SelectValue placeholder="Selectionner un type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">Toutes les formules</SelectItem>
                        {formuleList.map((formule) => (
                          <SelectItem key={formule} value={formule}>
                            {formule}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </section>
                <section className="mx-2 grid grid-cols-2 gap-2 md:space-y-1">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="studentData"
                      disabled={exportStyle === 'ALL'}
                      defaultChecked={true}
                      checked={exportFilters.selectedFields.student}
                      onCheckedChange={(checked: boolean) => {
                        setExportFilters({
                          ...exportFilters,
                          selectedFields: {
                            ...exportFilters.selectedFields,
                            student: checked,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="studentData"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Données de l'étudiant
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="referentData"
                      disabled={exportStyle === 'ALL'}
                      defaultChecked={true}
                      checked={exportFilters.selectedFields.referent}
                      onCheckedChange={(checked: boolean) => {
                        setExportFilters({
                          ...exportFilters,
                          selectedFields: {
                            ...exportFilters.selectedFields,
                            referent: checked,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="referentData"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Données de son responsable légal
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="scolarityData"
                      disabled={exportStyle === 'ALL'}
                      defaultChecked={true}
                      checked={exportFilters.selectedFields.pastScolarity}
                      onCheckedChange={(checked: boolean) => {
                        setExportFilters({
                          ...exportFilters,
                          selectedFields: {
                            ...exportFilters.selectedFields,
                            pastScolarity: checked,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="scolarityData"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Données sur la scolarité passée
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="formuleData"
                      disabled={exportStyle === 'ALL'}
                      defaultChecked={true}
                      checked={exportFilters.selectedFields.formule}
                      onCheckedChange={(checked: boolean) => {
                        setExportFilters({
                          ...exportFilters,
                          selectedFields: {
                            ...exportFilters.selectedFields,
                            formule: checked,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="formuleData"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Données de la formule choisie
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="paymentsData"
                      disabled={exportStyle === 'ALL'}
                      defaultChecked={true}
                      checked={exportFilters.selectedFields.payment}
                      onCheckedChange={(checked: boolean) => {
                        setExportFilters({
                          ...exportFilters,
                          selectedFields: {
                            ...exportFilters.selectedFields,
                            payment: checked,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="paymentsData"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Données des paiments
                    </label>
                  </div>
                </section>
                <section className="pt-2">
                  <h4 className="text-sm font-normal italic leading-none tracking-tight">
                    Options spécifiques
                  </h4>
                  <div className="my-2 flex w-full items-center space-x-2 bg-white">
                    <Switch
                      disabled={!exportFilters.recordStatus.submitted}
                      id="exportValidatedOnly"
                      className="data-[state=checked]:bg-green-600 data-[state=unchecked]:bg-gray-200"
                      defaultChecked={exportFilters.onlyValidated}
                      onCheckedChange={(checked) => {
                        setExportFilters({
                          ...exportFilters,
                          onlyValidated: checked,
                        });
                      }}
                      checked={exportFilters.onlyValidated}
                    />
                    <Label
                      htmlFor="exportValidatedOnly"
                      className="peer-disabled:opacity-30"
                    >
                      Inscriptions validées uniquement
                    </Label>
                  </div>
                  <div className="my-2 flex w-full items-center space-x-2 bg-white">
                    <Switch
                      disabled={!exportFilters.recordStatus.cancelled}
                      id="exportRefundedOnly"
                      className="data-[state=checked]:bg-green-600 data-[state=unchecked]:bg-gray-200"
                      defaultChecked={exportFilters.onlyRefunded}
                      onCheckedChange={(checked) => {
                        setExportFilters({
                          ...exportFilters,
                          onlyRefunded: checked,
                        });
                      }}
                      checked={exportFilters.onlyRefunded}
                    />
                    <Label
                      htmlFor="exportRefundedOnly"
                      className="peer-disabled:opacity-30"
                    >
                      Inscriptions remboursées uniquement
                    </Label>
                  </div>
                </section>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <div className="mt-4 flex justify-end">
            <Button
              className="bg-previsionorange text-white hover:bg-previsionorange/60"
              title="Télécharger"
              disabled={
                !(
                  exportFilters.recordStatus.pending ||
                  exportFilters.recordStatus.submitted ||
                  exportFilters.recordStatus.cancelled ||
                  exportFilters.recordStatus.archived ||
                  exportFilters.recordStatus.deleted
                )
              }
              onClick={() => {
                downloadGeneratedCSV(
                  exportFilters.recordStatus,
                  exportFilters.scholarYear,
                  exportFilters.formuleType,
                  exportFilters.onlyValidated,
                  exportFilters.onlyRefunded,
                  exportFilters.selectedFields,
                );
                toast('Création du fichier CSV en cours');
              }}
            >
              Télécharger
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
};
