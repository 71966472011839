import { UseNavigateResult } from '@tanstack/react-router';
import ls from 'localstorage-slim';

import { auth } from '@/lib/firebase';

import { LOCALSTORAGE_INSRIPTION_KEY } from './constants';

export const nukeLocalData = (navigate: UseNavigateResult<string>) => {
  auth.signOut();
  ls.remove(LOCALSTORAGE_INSRIPTION_KEY);
  navigate({ to: '/steps/one' });
};

export const wipeLocalStorage = () => {
  ls.remove(LOCALSTORAGE_INSRIPTION_KEY);
};
