import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

import { DB_NAME, firebaseConfig } from '../../firebaseCredentials.ts';

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app, DB_NAME ?? '(default)');
export const storage = getStorage(app);
export const functions = getFunctions(app);

// Connect to the Firebase Emulator if running locally else use the production services
if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export default app;
