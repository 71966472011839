export const uidGenerate = function () {
  let id = Date.now().toString(36) + Math.random().toString(36).substr(2);

  const randomChar = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  while (id.length < 28) {
    id += randomChar();
  }

  return id;
};
