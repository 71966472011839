import { CatchBoundary, createFileRoute, redirect } from '@tanstack/react-router';
import ls from 'localstorage-slim';

import { getRightPanelContent } from '@/data/getters/admin';
import { NotFoundPage } from '@/pages/404';
import { LoaderPage } from '@/pages/loader';
import { StepsLayout } from '@/pages/steps';
import { SchoolRecord } from '@/types/types';
import { wipeLocalStorage } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/steps')({
  loader: async ({ location }) => {
    if (location.pathname === '/steps' || location.pathname === '/steps/') {
      throw redirect({
        to: '/',
      });
    }

    const subFolder = location.pathname.split('/')[2];
    if (
      subFolder !== 'one' &&
      subFolder !== 'two' &&
      subFolder !== 'three' &&
      subFolder !== 'four' &&
      subFolder !== 'five' &&
      subFolder !== 'six' &&
      subFolder !== 'seven' &&
      subFolder !== 'eight'
    ) {
      throw redirect({
        to: '/',
      });
    }

    const pendingInscription: SchoolRecord | null =
      (await ls.get(LOCALSTORAGE_INSRIPTION_KEY)) || null;

    const rightPanel: { content: string } | null = (await getRightPanelContent()) || null;

    return {
      pendingInscription,
      content: rightPanel ? (rightPanel as { content: string }).content : '',
    };
  },
  pendingComponent: () => (
    <LoaderPage
      bgClassName="bg-gray-100 h-screen !w-screen"
      pointClassName="bg-previsionblue"
    />
  ),
  component: () => (
    <CatchBoundary
      getResetKey={() => 'reset'}
      errorComponent={() => (
        <NotFoundPage
          primaryColor="previsionblue"
          buttonText="Réessayer"
          buttonLink="/steps/one"
          description="Le site a rencontré une erreur."
          isOnTop
          displayAdvices
          buttonFunction={wipeLocalStorage}
        />
      )}
      onCatch={(error) => {
        console.error(error);
        wipeLocalStorage();
      }}
    >
      <StepsLayout />
    </CatchBoundary>
  ),
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Réessayer"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
      isOnTop
      displayAdvices
      buttonFunction={wipeLocalStorage}
    />
  ),
});
