import { useLoaderData, useRouter } from '@tanstack/react-router';
import clsx from 'clsx';
import { LoaderCircle, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { SectionSubtitle, SectionTitle } from '@/components/titles';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  addScholarityType,
  addYear,
  deleteType,
  deleteYear,
  updateType,
  updateYear,
} from '@/data/setters/admin';
import { FirebaseCombobox } from '@/types/types';

export const SettingsFormulePage = () => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [modalType, setModalType] = useState<
    'update' | 'delete' | 'add' | 'update-type' | 'delete-type' | 'add-type'
  >('add');
  const [openModal, setOpenModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState<FirebaseCombobox | null>(null);
  const [selectedType, setSelectedType] = useState<FirebaseCombobox | null>(null);

  const { years, types } = useLoaderData({
    from: '/admin/settings/formules',
  });

  useEffect(() => {
    if (years.length > 0) {
      //@ts-expect-error - Object cannot be undefined
      setSelectedYear(years[0]);
    }
    if (types.length > 0) {
      //@ts-expect-error - Object cannot be undefined
      setSelectedType(types[0]);
    }
  }, [years, types]);

  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  return (
    <section className="min-h-[calc(100vh-5rem)] w-full bg-previsiongrey p-2 md:w-[calc(100%-20rem)]">
      <SectionTitle title={'Paramètres - Formules'} />
      <div className="my-4 rounded-sm bg-white px-4">
        <SectionSubtitle title={'Liste des années disponibles'} className="py-1" />
        <p className="text-sm text-gray-500">
          <span className="hidden md:block">
            Effectuez un clic droit sur une année pour accéder aux actions disponibles.
            <br />
          </span>
          <span className="italic">
            Attention : la suppression d'une année en cours d'utilisation peut entrainer
            des erreurs.
          </span>
        </p>
        <div className="hidden flex-wrap gap-x-2 gap-y-3 rounded-sm bg-white p-4 md:flex">
          {years.map((year, index) => (
            <ContextMenu key={index}>
              <ContextMenuTrigger>
                <Badge
                  className="flex w-[110px] justify-center rounded-sm border border-previsionorange bg-white p-2 text-black hover:cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    toast('Clic droit pour accéder aux actions');
                  }}
                >
                  {year.position && `${year.position} -`} {year.value.substring(0, 9)}
                </Badge>
              </ContextMenuTrigger>
              <ContextMenuContent>
                <ContextMenuItem
                  onClick={() => {
                    setModalType('update');
                    setSelectedYear(year);
                    setOpenModal(true);
                  }}
                >
                  Mettre a jour l'année
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() => {
                    setModalType('delete');
                    setSelectedYear(year);
                    setOpenModal(true);
                  }}
                >
                  Supprimer l'année
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          ))}
          <Badge
            className="flex w-[100px] justify-center rounded-sm border border-dashed border-previsionorange bg-white p-2 text-black hover:cursor-pointer hover:bg-gray-200"
            onClick={() => {
              setModalType('add');
              setOpenModal(true);
            }}
          >
            <Plus size={16} strokeWidth={2} />
            Ajouter
          </Badge>
        </div>
        {/* Mobile for years */}
        <div className="flex space-x-2 rounded-md bg-white p-4 md:hidden">
          <Select
            onValueChange={(value) => {
              const foundYear = years.find((year) => year.value === value);
              if (foundYear) {
                setSelectedYear(foundYear);
              } else {
                setSelectedYear(null);
              }
            }}
            // TODO: Shitty trick to pass typescript between refactoring
            value={selectedYear ? selectedYear.value : ''}
            onOpenChange={(open) => {
              if (open) {
                setIsSelectOpen(true);
              } else {
                setTimeout(() => {
                  setIsSelectOpen(false);
                }, 500);
              }
            }}
          >
            <SelectTrigger className="">
              <SelectValue placeholder={'Sélectionner une année'} />
            </SelectTrigger>
            <SelectContent>
              {years.map((year, index) => (
                <SelectItem value={year.value} key={index}>
                  {year.value}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Badge
            className="flex w-[100px] justify-center rounded-sm border border-dashed border-previsionorange bg-white p-2 text-black hover:cursor-pointer hover:bg-gray-200"
            onClick={() => {
              setModalType('add');
              setOpenModal(true);
            }}
          >
            <Plus size={16} strokeWidth={2} />
            Ajouter
          </Badge>
        </div>
        <div
          className={clsx(
            'justify-between px-4 py-2 md:hidden',
            selectedYear ? 'flex' : 'hidden',
          )}
        >
          <Button
            variant="dashboardBlue"
            onClick={() => {
              setModalType('update');
              setOpenModal(true);
            }}
            disabled={!selectedYear || isSelectOpen}
          >
            Mettre a jour<span className="hidden sm:block">&nbsp;l'année</span>
          </Button>
          <Button
            variant="dashboardRed"
            onClick={() => {
              setModalType('delete');
              setOpenModal(true);
            }}
            disabled={!selectedYear || isSelectOpen}
          >
            Supprimer<span className="hidden sm:block">&nbsp;l'année</span>
          </Button>
        </div>
        {/* End */}
      </div>
      <div className="my-4 rounded-sm bg-white px-4">
        <SectionSubtitle title={'Liste des types de formules'} className="py-1" />
        <p className="text-sm text-gray-500">
          <span className="hidden md:block">
            Effectuez un clic droit sur un type pour accéder aux actions disponibles.
            <br />
          </span>
          <span className="italic">
            Attention : la suppression d'un type en cours d'utilisation peut entrainer des
            erreurs.
          </span>
        </p>
        <div className="hidden flex-wrap gap-x-2 gap-y-3 rounded-sm bg-white p-4 md:flex">
          {types.map((type, index) => (
            <ContextMenu key={index}>
              <ContextMenuTrigger>
                <Badge
                  className="flex w-[250px] justify-center truncate rounded-sm border border-previsionorange bg-white p-2 text-black hover:cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    toast('Clic droit pour accéder aux actions');
                  }}
                >
                  {type.position && `${type.position} -`} {type.label.slice(0, 30)}
                </Badge>
              </ContextMenuTrigger>
              <ContextMenuContent>
                <ContextMenuItem
                  onClick={() => {
                    setModalType('update-type');
                    setSelectedType(type);
                    setOpenModal(true);
                  }}
                >
                  Mettre a jour le type
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() => {
                    setModalType('delete-type');
                    setSelectedType(type);
                    setOpenModal(true);
                  }}
                >
                  Supprimer le type
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          ))}
          <Badge
            className="flex w-[100px] justify-center rounded-sm border border-dashed border-previsionorange bg-white p-2 text-black hover:cursor-pointer hover:bg-gray-200"
            onClick={() => {
              setModalType('add-type');
              setOpenModal(true);
            }}
          >
            <Plus size={16} strokeWidth={2} />
            Ajouter
          </Badge>
        </div>
        <div className="flex space-x-2 rounded-md bg-white p-4 md:hidden">
          <Select
            onValueChange={(value) => {
              const foundType = types.find((type) => type.value === value);
              if (foundType) {
                setSelectedType(foundType);
              } else {
                setSelectedType(null);
              }
            }}
            // TODO: Shitty trick to pass typescript between refactoring
            value={selectedType ? selectedType.value : ''}
            onOpenChange={(open) => {
              if (open) {
                setIsSelectOpen(true);
              } else {
                setTimeout(() => {
                  setIsSelectOpen(false);
                }, 500);
              }
            }}
          >
            <SelectTrigger className="">
              <SelectValue placeholder={'Sélectionner un type'} />
            </SelectTrigger>
            <SelectContent>
              {types.map((type, index) => (
                <SelectItem value={type.value} key={index}>
                  {type.value}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Badge
            className="flex w-[100px] justify-center rounded-sm border border-dashed border-previsionorange bg-white p-2 text-black hover:cursor-pointer hover:bg-gray-200"
            onClick={() => {
              setModalType('add-type');
              setOpenModal(true);
            }}
          >
            <Plus size={16} strokeWidth={2} />
            Ajouter
          </Badge>
        </div>
        <div
          className={clsx(
            'justify-between px-4 py-2 md:hidden',
            selectedType ? 'flex' : 'hidden',
          )}
        >
          <Button
            variant="dashboardBlue"
            onClick={() => {
              setModalType('update-type');
              setOpenModal(true);
            }}
            disabled={!selectedType || isSelectOpen}
          >
            Mettre a jour<span className="hidden sm:block">&nbsp;le type</span>
          </Button>
          <Button
            variant="dashboardRed"
            onClick={() => {
              setModalType('delete-type');
              setOpenModal(true);
            }}
            disabled={!selectedType || isSelectOpen}
          >
            Supprimer<span className="hidden sm:block">&nbsp;le type</span>
          </Button>
        </div>
      </div>
      <Dialog open={openModal} onOpenChange={(open) => setOpenModal(open)}>
        {modalType === 'add' && (
          <AddModal setOpenModal={setOpenModal} forMethod="yearMethod" />
        )}
        {modalType === 'update' && selectedYear && (
          <EditModal
            element={selectedYear as FirebaseCombobox}
            setOpenModal={setOpenModal}
            forMethod="yearMethod"
          />
        )}
        {modalType === 'delete' && selectedYear && (
          <DeleteModal
            element={selectedYear as FirebaseCombobox}
            setOpenModal={setOpenModal}
            forMethod="yearMethod"
          />
        )}
        {modalType === 'add-type' && (
          <AddModal setOpenModal={setOpenModal} forMethod="typeMethod" />
        )}
        {modalType === 'update-type' && selectedType && (
          <EditModal
            element={selectedType as FirebaseCombobox}
            setOpenModal={setOpenModal}
            forMethod="typeMethod"
          />
        )}
        {modalType === 'delete-type' && selectedType && (
          <DeleteModal
            element={selectedType as FirebaseCombobox}
            setOpenModal={setOpenModal}
            forMethod="typeMethod"
          />
        )}
      </Dialog>
    </section>
  );
};

const AddModal = ({
  setOpenModal,
  forMethod,
}: {
  setOpenModal: (open: boolean) => void;
  forMethod: 'yearMethod' | 'typeMethod';
}) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          Ajouter{' '}
          {forMethod === 'yearMethod'
            ? 'une nouvelle année'
            : 'un nouveau type de formule'}
          ?
        </DialogTitle>
        <DialogDescription
          className={clsx(forMethod === 'yearMethod' ? 'block' : 'hidden')}
        >
          Vous pouvez également ajouter une année scolaire directement lors de la création
          d'une formule.
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsLoading(true);

          const form = e.target as HTMLFormElement;
          const title = (form.elements[0] as HTMLInputElement).value;
          const position = (form.elements[1] as HTMLInputElement).value;

          if (title === '') {
            toast('Veuillez renseigner une année');
            setIsLoading(false);
            return;
          }

          if (position !== '' && isNaN(parseInt(position))) {
            toast('La position doit être un nombre');
            setIsLoading(false);
            return;
          } else if (position !== '' && parseInt(position) < 1) {
            toast('La position doit être supérieure à 0');
            setIsLoading(false);
            return;
          }

          const res = await (forMethod === 'yearMethod'
            ? addYear(title, position)
            : addScholarityType(title, position));

          setIsLoading(false);

          if (res) {
            router.invalidate();
            setOpenModal(false);
          }
        }}
      >
        <div>
          <Label>{forMethod === 'yearMethod' ? 'Année' : 'Type'}</Label>
          <Input
            type="text"
            placeholder={
              forMethod === 'yearMethod' ? '2025/2026 ou 2025' : 'Type de formule'
            }
            className="w-full"
            required
          />
        </div>
        <div>
          <Label>
            Position{' '}
            <span className="text-xs text-gray-400">
              (Optionnel, si non renseigné, la position sera automatique)
            </span>
          </Label>
          <Input type="number" placeholder="1,2,3,..." className="w-full" />
        </div>
        <DialogFooter className="flex justify-end py-1 md:justify-between">
          <Button
            className="my-auto hidden min-w-24 md:block"
            variant="dashboardOutlinedBlue"
            onClick={() => setOpenModal(false)}
            type="button"
            disabled={isLoading}
          >
            Annuler
          </Button>
          <Button variant="dashboardBlue" type="submit" disabled={isLoading}>
            {isLoading && <LoaderCircle className="animate-spin" />}
            {!isLoading && 'Ajouter'}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
};

const EditModal = ({
  element,
  setOpenModal,
  forMethod,
}: {
  element: FirebaseCombobox;
  setOpenModal: (open: boolean) => void;
  forMethod: 'yearMethod' | 'typeMethod';
}) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Mise a jour - {element.value}?</DialogTitle>
        <DialogDescription>
          Cela est une action définitive.
          <br />
          <span className="italic">
            Les modifications effectuées sur cet onglet sont globale et ne mettent pas a
            jour les formules existantes.
          </span>
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsLoading(true);

          const elementId = element.id ? element.id : element.value;

          const form = e.target as HTMLFormElement;
          const title =
            (form.elements[0] as HTMLInputElement).value !== ''
              ? (form.elements[0] as HTMLInputElement).value
              : element.value;

          const position =
            (form.elements[1] as HTMLInputElement).value !== ''
              ? (form.elements[1] as HTMLInputElement).value
              : element.position;

          if (title === element.value && position === element.position) {
            toast('Aucune modification détectée');
            setIsLoading(false);
            return;
          }

          if (title !== null && title === '') {
            toast('Veuillez renseigner une année');
            setIsLoading(false);
            return;
          }

          if (position !== null) {
            if (position !== '' && isNaN(parseInt(position))) {
              toast('La position doit être un nombre');
              setIsLoading(false);
              return;
            } else if (position !== '' && parseInt(position) < 1) {
              toast('La position doit être supérieure à 0');
              setIsLoading(false);
              return;
            }
          }

          const isOnlyPositionChanged =
            title === element.value && position !== element.position;

          const res = await (forMethod === 'yearMethod'
            ? updateYear(elementId, title, position, isOnlyPositionChanged)
            : updateType(elementId, title, position, isOnlyPositionChanged, true));

          setIsLoading(false);
          if (res) {
            router.invalidate();
            setOpenModal(false);
          }
        }}
      >
        <div>
          <Label>{forMethod === 'yearMethod' ? 'Année' : 'Type'} </Label>
          <Input type="text" placeholder={element.value} className="w-full" />
        </div>
        <div>
          <Label>Position </Label>
          <Input
            type="string"
            placeholder={element.position ? element.position : '1,2,3,...'}
            className="w-full"
          />
        </div>
        <DialogFooter className="flex justify-end py-1 md:justify-between">
          <Button
            variant="dashboardOutlinedBlue"
            onClick={() => setOpenModal(false)}
            type="button"
            disabled={isLoading}
          >
            Annuler
          </Button>
          <Button variant="dashboardBlue" type="submit" disabled={isLoading}>
            {isLoading && <LoaderCircle className="animate-spin" />}
            {!isLoading && 'Mettre à jour'}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
};

const DeleteModal = ({
  element,
  setOpenModal,
  forMethod,
}: {
  element: FirebaseCombobox;
  setOpenModal: (open: boolean) => void;
  forMethod: 'yearMethod' | 'typeMethod';
}) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    const elementId = element.id ? element.id : element.value;

    if (forMethod === 'yearMethod') {
      try {
        await deleteYear(elementId);
        setIsLoading(false);
        router.invalidate();
        setOpenModal(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error deleting document: ', error);
        toast("Erreur lors de la suppression de l'année scolaire");
      }
    }
    if (forMethod === 'typeMethod') {
      try {
        await deleteType(elementId);
        setIsLoading(false);
        router.invalidate();
        setOpenModal(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error deleting document: ', error);
        toast('Erreur lors de la suppression du type de formule');
      }
    }
  };

  return (
    <DialogContent className="w-full">
      <DialogHeader>
        <DialogTitle>
          Etês-vous sûr de vouloir supprimer{' '}
          {forMethod === 'yearMethod' ? "l'année" : 'le type'} {element.value} ?
        </DialogTitle>
        <DialogDescription>
          Cela est une action irréversible.
          <br />
          <span className={clsx(forMethod === 'yearMethod' ? 'block' : 'hidden')}>
            Si l'année est en cours d'utilisation, cela peut entrainer des erreurs.
          </span>
        </DialogDescription>
      </DialogHeader>
      <DialogFooter className="flex justify-end md:justify-between">
        <Button
          variant="dashboardOutlinedBlue"
          className="my-auto hidden min-w-24 md:block"
          onClick={() => setOpenModal(false)}
        >
          Annuler
        </Button>
        <Button variant="dashboardRed" className="min-w-32" onClick={handleDelete}>
          {isLoading && <LoaderCircle className="animate-spin" />}
          {!isLoading && 'Je confirme la suppression'}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
