import { createFileRoute, notFound } from '@tanstack/react-router';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { InscriptionDetailsPage } from '@/pages/admin/inscriptions/details';
import { LoaderPage } from '@/pages/loader';
import { Formule, SchoolRecordEnforced } from '@/types/types';

export const Route = createFileRoute('/admin/inscriptions/$inscriptionId')({
  loader: async ({ params: { inscriptionId } }) => {
    const docRef = doc(db, 'inscriptions', inscriptionId);
    const docSnap = await getDoc(docRef);

    // @ts-expect-error -- Type is know but data is from firestore
    const data: SchoolRecordEnforced =
      {
        recordId: docSnap.id,
        ...docSnap.data(),
      } || null;

    if (!docSnap.exists()) {
      throw notFound();
    }

    const IDFormule = data.formule ? data.formule.formuleId : null;

    let formuleData: Formule | null = null;
    if (IDFormule) {
      const formuleRef = doc(db, 'formules', IDFormule);
      const formuleSnap = await getDoc(formuleRef);
      // @ts-expect-error -- Type is know but data is from firestore
      formuleData = {
        id: formuleSnap.id,
        ...formuleSnap.data(),
      };
    }

    return { data, formuleData };
  },
  pendingComponent: () => <LoaderPage />,
  component: () => <InscriptionDetailsPage />,
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
