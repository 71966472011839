import { collection, doc, setDoc, SetOptions } from 'firebase/firestore';

import { db } from '@/lib/firebase';

export const saveDocumentToFirebase = async (
  collectionPath: string,
  documentName: string,
  data: object,
  setDocParams?: SetOptions,
) => {
  try {
    const colRef = collection(db, collectionPath);
    const docRef = doc(colRef, documentName);
    if (setDocParams) {
      await setDoc(docRef, data, setDocParams);
    } else {
      await setDoc(docRef, data);
    }
  } catch (e) {
    throw new Error('Error while saving document to firebase');
  }
};

export const getValidDate = (dateString?: string | Date): Date => {
  if (!dateString) {
    return new Date();
  }
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? new Date() : date;
};
