import { createFileRoute, redirect } from '@tanstack/react-router';

import { auth } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { LoginPage } from '@/pages/login';

export const Route = createFileRoute('/login')({
  validateSearch: (search) =>
    search as {
      origin: string | undefined;
    },
  beforeLoad: async () => {
    await auth.authStateReady();
    if (auth.currentUser) {
      if (auth.currentUser.isAnonymous) {
        auth.signOut();
      } else {
        throw redirect({
          to: '/admin/inscriptions/pending',
        });
      }
    }
  },
  component: () => <LoginPage />,
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      buttonText="Retour a l'accueil"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
    />
  ),
});
