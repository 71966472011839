import { createFileRoute, redirect } from '@tanstack/react-router';
import ls from 'localstorage-slim';

import { NotFoundPage } from '@/pages/404';
import { ConfirmationPage } from '@/pages/inscriptions/confirmation';
import { SchoolRecord } from '@/types/types';
import { wipeLocalStorage } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/steps/eight')({
  validateSearch: (search) =>
    search as {
      isFromCallbackPayment: boolean;
    },
  beforeLoad: ({ search: { isFromCallbackPayment } }) => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (!pendingInscription) {
      console.error('Aucun utilisateur trouvé');
      console.error('Redirection vers la dernière étape accessible');
      throw redirect({ to: '/steps' });
    }

    if (pendingInscription && pendingInscription.formProgress < 7) {
      console.error("L'utilisateur n'a pas accèdé à l'étape 7");
      console.error('Redirection vers la dernière étape accessible');
      throw redirect({
        to: '/steps',
      });
    }

    if (!isFromCallbackPayment && pendingInscription.formProgress === 8) {
      console.error("L'utilisateur n'a pas de callback de paiement");
      console.error("Mais il a déja accèdé à l'étape 8");
      console.error('Redirection vers la dernière étape du formulaire');
      return;
    }

    if (!isFromCallbackPayment) {
      console.error('Ne viens pas de la page de paiement');
      console.error('Redirection vers la dernière étape accessible');
      throw redirect({
        to: '/steps',
      });
    }

    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...pendingInscription,
      formProgress:
        pendingInscription && pendingInscription.formProgress >= 8
          ? pendingInscription.formProgress
          : 8,
      confirmationDisplayed: true,
    });
  },
  component: () => <ConfirmationPage />,
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Réessayer"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
      isOnTop
      resetHeader
      displayAdvices
      buttonFunction={wipeLocalStorage}
    />
  ),
});
