import { SquareArrowLeft, SquareCheck, SquareMinus, SquarePen } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Payment } from '@/types/types';
import { paymentTitleConverter } from '@/utils/titleConverter';

export const PaymentCalendar = ({
  infos,
  triggerEdit,
}: {
  infos: Payment;
  triggerEdit: (
    from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files' | 'payments',
  ) => void;
}) => {
  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold text-previsionblue">CALENDRIER DE PAIEMENT</h3>
        <Button
          variant="outline"
          className="w-auto"
          onClick={() => {
            triggerEdit('payments');
          }}
        >
          <SquarePen size={24} />
        </Button>
      </div>
      {/* 
      Au cas ou c demander par le client un jour il reste la
      <div>
        <Select defaultValue="bank-debit">
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Selectionner une méthode de paiement" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="cheque">Chèque</SelectItem>
              <SelectItem value="cash">Espèces</SelectItem>
              <SelectItem value="bank-transfer">Virement bancaire</SelectItem>
              <SelectItem value="credit-card">Carte bancaire</SelectItem>
              <SelectItem value="bank-debit">Prélèvement</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div> */}
      <div>
        <div className="flex w-full text-sm font-bold text-black/40 [&>*]:w-1/4 [&>*]:min-w-14  [&>*]:text-ellipsis ">
          <p>Mois</p>
          <p>Réduction</p>
          <p>Total (€)</p>
          <p>Statut</p>
        </div>
        {infos.schedule &&
          infos.schedule.map((payment, index) => {
            if (payment.paymentType === 'total') {
              return (
                <div key={index}>
                  <Separator className="my-4" />
                  <div className="my-2 flex w-full text-sm text-black [&>*]:w-1/4 [&>*]:min-w-14  [&>*]:text-ellipsis ">
                    <p>Total</p>
                    <p>{infos.totalReduction}</p>
                    <p>{payment.paymentAmount / 100} €</p>
                    <p>
                      {payment.paymentStatus === 'paid' && (
                        <SquareCheck className="text-green-600" />
                      )}
                      {payment.paymentStatus === 'pending' && (
                        <SquareMinus className="text-gray-600" />
                      )}
                      {payment.paymentStatus === 'refunded' && (
                        <SquareArrowLeft className="text-red-600" />
                      )}
                    </p>
                  </div>
                </div>
              );
            }

            return (
              <div
                key={index}
                className="my-2 flex w-full text-sm text-black [&>*]:w-1/4 [&>*]:min-w-14  [&>*]:overflow-hidden [&>*]:text-ellipsis "
              >
                <p>{paymentTitleConverter(payment.paymentType)}</p>
                <p>{payment.paymentReduction}</p>
                <p>{payment.paymentAmount / 100} €</p>
                <p>
                  {payment.paymentStatus === 'paid' && (
                    <SquareCheck className="text-green-600" />
                  )}
                  {payment.paymentStatus === 'pending' && (
                    <SquareMinus className="text-gray-600" />
                  )}
                  {payment.paymentStatus === 'refunded' && (
                    <SquareArrowLeft className="text-red-600" />
                  )}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
