import { zodResolver } from '@hookform/resolvers/zod';
import { useParams, useRouter } from '@tanstack/react-router';
import { doc, DocumentData, getDoc, setDoc } from 'firebase/firestore';
import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
/* import { previsionDB, PrevisionSchema } from '@/data/typesaurus'; */
import { db } from '@/lib/firebase';

export const PastScolarityEdit = (/* {
  toggleModal,
}: {
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
} */) => {
  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const [selectedEdit, setSelectedEdit] = useState<'1er' | 'Ter' | 'Motivation'>('Ter');
  const [record, setRecord] = useState<DocumentData | null | undefined>(null);

  const fetchRecord = async () => {
    const docRef = doc(db, 'inscriptions', inscriptionId);
    const docData = await getDoc(docRef);

    setRecord(docData.data());
  };

  useEffect(() => {
    fetchRecord();
  }, []);

  if (!record) {
    return (
      <DialogContent>
        <LoaderCircle className="m-auto animate-spin text-previsionblue" size={32} />
      </DialogContent>
    );
  }

  return (
    <DialogContent>
      <DialogTitle className="text-xl font-bold text-previsionblue">
        Modifier les informations de Spécialités
      </DialogTitle>

      <Select
        value={selectedEdit}
        onValueChange={(value) => setSelectedEdit(value as '1er' | 'Ter' | 'Motivation')}
      >
        <SelectTrigger>
          <SelectValue placeholder="Modification de ..." />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="Ter">Modification des options de Terminale</SelectItem>
            <SelectItem value="1er">Modification des options de Première</SelectItem>
            <SelectItem value="Motivation">
              Modification du texte de motivation
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Separator className="my-1" />

      {selectedEdit === '1er' ? (
        <ElevenGradesForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
      {selectedEdit === 'Ter' ? (
        <TwelveGradesForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
      {selectedEdit === 'Motivation' ? (
        <MotivationForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
    </DialogContent>
  );
};

/******** Form for "Terminale" section ********/
const TwelveGradesFormSchema = z.object({
  twelveGradesSpecialitiesOne: z.string().min(1, {
    message: 'Spécialité requise',
  }),
  twelveGradesSpecialitiesTwo: z.string().min(1, {
    message: 'Spécialité requise',
  }),
  twelveGradesSpecialitiesVariant: z.string().max(255).optional(),
  twelveGradesDroppedSpecialities: z.string().min(1, {
    message: 'Champs requis',
  }),
});
const TwelveGradesForm = ({
  record,
  //toggleModal,
}: {
  record: DocumentData;
  //toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const terForm = useForm<z.infer<typeof TwelveGradesFormSchema>>({
    resolver: zodResolver(TwelveGradesFormSchema),
    defaultValues: {
      twelveGradesSpecialitiesOne: record.scolarity.twelveGradesSpecialities.one ?? '',
      twelveGradesSpecialitiesTwo: record.scolarity.twelveGradesSpecialities.two ?? '',
      twelveGradesSpecialitiesVariant:
        record.scolarity.twelveGradesSpecialities.variant ?? '',
      twelveGradesDroppedSpecialities:
        record.scolarity.twelveGradesSpecialities.dropped ?? '',
    },
  });

  if (!inscriptionId) {
    return null;
  }

  return (
    <Form {...terForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={terForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  twelveGradesSpecialities: {
                    one: data.twelveGradesSpecialitiesOne,
                    two: data.twelveGradesSpecialitiesTwo,
                    variant: data.twelveGradesSpecialitiesVariant ?? null,
                    dropped: data.twelveGradesDroppedSpecialities,
                  },
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification des spécialités Terminale - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col space-y-2 overflow-y-auto py-2 text-black/60">
          <FormField
            control={terForm.control}
            name="twelveGradesSpecialitiesOne"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Terminale - Spécialité 1</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={terForm.control}
            name="twelveGradesSpecialitiesTwo"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Terminale - Spécialité 2</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={terForm.control}
            name="twelveGradesSpecialitiesVariant"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Option</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={terForm.control}
            name="twelveGradesDroppedSpecialities"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Spécialité abandonnée en Première
                </FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button
          variant="dashboardBlue"
          disabled={terForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {terForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Terminale'
          )}
        </Button>
      </form>
    </Form>
  );
};

/******** Form for "Première" section ********/
const ElevenGradesFormSchema = z.object({
  elevenGradesSpecialitiesOne: z.string().min(1, {
    message: 'Spécialité requise',
  }),
  elevenGradesSpecialitiesTwo: z.string().min(1, {
    message: 'Spécialité requise',
  }),
  elevenGradesSpecialitiesThree: z.string().max(255).optional(),
});
const ElevenGradesForm = ({
  record,
  //toggleModal,
}: {
  record: DocumentData;
  //toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const preForm = useForm<z.infer<typeof ElevenGradesFormSchema>>({
    resolver: zodResolver(ElevenGradesFormSchema),
    defaultValues: {
      elevenGradesSpecialitiesOne: record.scolarity.diplomaSpecialities?.[0] ?? '',
      elevenGradesSpecialitiesTwo: record.scolarity.diplomaSpecialities?.[1] ?? '',
      elevenGradesSpecialitiesThree: record.scolarity.diplomaSpecialities?.[2] ?? '',
    },
  });

  return (
    <Form {...preForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={preForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  diplomaSpecialities: [
                    data.elevenGradesSpecialitiesOne,
                    data.elevenGradesSpecialitiesTwo,
                    ...(data.elevenGradesSpecialitiesThree
                      ? [data.elevenGradesSpecialitiesThree]
                      : []),
                  ],
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification des spécialités Première - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col space-y-2 overflow-y-auto py-2 text-black/60">
          <FormField
            control={preForm.control}
            name="elevenGradesSpecialitiesOne"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Première - Spécialité 1</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={preForm.control}
            name="elevenGradesSpecialitiesTwo"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Première - Spécialité 2</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={preForm.control}
            name="elevenGradesSpecialitiesThree"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Première - Spécialité 3</FormLabel>
                <Input
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button
          variant="dashboardBlue"
          disabled={preForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {preForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Première'
          )}
        </Button>
      </form>
    </Form>
  );
};

/******** Form for "Motivation" section ********/
const MotivationFormSchema = z.object({
  studentMotivation: z.string().min(1, {
    message: 'Texte de motivation requis',
  }),
});
const MotivationForm = ({
  record,
  //toggleModal,
}: {
  record: DocumentData;
  //toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const motivationForm = useForm<z.infer<typeof MotivationFormSchema>>({
    resolver: zodResolver(MotivationFormSchema),
    defaultValues: {
      studentMotivation: record.scolarity.motivation ?? '',
    },
  });

  if (!inscriptionId) {
    return null;
  }

  return (
    <Form {...motivationForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={motivationForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  motivation: data.studentMotivation,
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification du texte de motivation - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col space-y-2 overflow-y-auto py-2 text-black/60">
          <FormField
            control={motivationForm.control}
            name="studentMotivation"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Motivation</FormLabel>
                <Textarea
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button
          variant="dashboardBlue"
          disabled={motivationForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {motivationForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Motivation'
          )}
        </Button>
      </form>
    </Form>
  );
};
