import ls from 'localstorage-slim';
import { z } from 'zod';

import { SchoolRecord } from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

const previousLocalstorage: SchoolRecord | null =
  ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

export const studentSchema = z
  .object({
    civility: z.enum(['M', 'F']),
    firstname: z.string().min(1, { message: 'Prénom requis' }),
    lastname: z.string().min(1, {
      message: 'Nom requis',
    }),
    birthdate: z.date({
      required_error: 'Date de naissance requise',
      invalid_type_error: 'Date de naissance invalide',
    }),
    placeOfBirth: z.string().min(1, {
      message: 'Lieu de naissance requis',
    }),
    nationality: z.string().min(1, {
      message: 'Nationalité requise',
    }),
    address: z.string().min(1, {
      message: 'Adresse requise',
    }),
    postalCode: z.string().min(1, {
      message: 'Code postal requis',
    }),
    city: z.string().min(1, {
      message: 'Ville requise',
    }),
    country: z.string().min(1, {
      message: 'Pays requis',
    }),
    email: z.string().email({
      message: 'Adresse mail invalide',
    }),
    mobilePhone: z.string().min(10, {
      message: 'Numéro de téléphone portable invalide',
    }),
    fixedPhone: z.string(),
    highchool: z.string().min(1, {
      message: 'Lycée actuel ou dernier lycée fréquenté requis',
    }),
  })
  .required();

export const referentSchema = z
  .object({
    civility: z.enum(['M', 'F']),
    referentFirstName: z.string().min(1, {
      message: 'Prénom requis',
    }),
    referentLastName: z.string().min(1, {
      message: 'Nom requis',
    }),
    referentBirthDate: z.date({
      required_error: 'Date de naissance requise',
      invalid_type_error: 'Date de naissance invalide',
    }),
    referentPlaceOfBirth: z.string().min(1, {
      message: 'Lieu de naissance requis',
    }),
    referentAddress: z.string().min(1, {
      message: 'Adresse requise',
    }),
    referentPostalCode: z.string().min(1, {
      message: 'Code postal requis',
    }),
    referentCity: z.string().min(1, {
      message: 'Ville requise',
    }),
    referentCountry: z.string().min(1, {
      message: 'Pays requis',
    }),
    referentEmail: z.string().email(),
    referentMobilePhone: z.string().min(10, {
      message: 'Numéro de téléphone portable requis',
    }),
    referentHomePhone: z.string().max(255),
  })
  .required()
  .refine(
    (data) => {
      if (data.referentEmail === previousLocalstorage?.student?.contact.email) {
        return false;
      }
      return true;
    },
    {
      message:
        'Votre adresse mail et celle de votre référent ne peuvent pas être les mêmes.',
      path: ['referentEmail'],
    },
  );

export const scolaritySchema = z.object({
  twelveGradesSpecialitiesOne: z.string().min(1, {
    message: 'Spécialité requise',
  }),
  twelveGradesSpecialitiesTwo: z.string().min(1, {
    message: 'Spécialité requise',
  }),
  twelveGradesSpecialitiesVariant: z.string().max(255).optional(),
  twelveGradesDroppedSpecialities: z.string().min(1, {
    message: 'Champs requis',
  }),
  studentMotivation: z.string().min(1, {
    message: 'Motivation requise',
  }),
});

export const termsSchema = z.object({
  gtcConsent: z.boolean({
    required_error: 'Vous devez accepter les conditions générales de vente',
  }),
  emailConsent: z.boolean({
    required_error: 'Vous devez accepter de recevoir des emails',
  }),
  scholarityRulesConsent: z.boolean({
    required_error: 'Vous devez accepter les règles de scolarité',
  }),
  ndaConsent: z.boolean({
    required_error: "Vous devez accepter l'accord de non divulgation",
  }),
  imageUseConsent: z.boolean({
    required_error: "Vous devez accepter l'utilisation de vos images",
  }),
  studentLegalResponsibilityWaiver: z.boolean({
    required_error: 'Vous devez accepter les responsabilités légales',
  }),
});

export const loginSchema = z.object({
  email: z.string().email({ message: "L'adresse e-mail n'est pas valide" }),
  password: z
    .string()
    .min(6, { message: 'Le mot de passe doit contenir au moins 6 caractères' }),
});

export const addFormuleSchema = z.object({
  schoolYear: z.string(),
  courseType: z.string(),
  courseTitle: z.string(),
  coursePrice: z.string(),
  courseReduction: z.string(),
  courseDoesExpire: z.boolean(),
  courseExpirationDate: z.string().optional(),
  position: z.string().optional(),
});
