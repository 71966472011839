import { Link } from '@tanstack/react-router';
import { LucideIcon } from 'lucide-react';

import { AccordionContent } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';

type NavigationCellProps = {
  title: string;
  path: string;
  icon: LucideIcon;
};

export const NavigationCell = ({ title, path, icon: Icon }: NavigationCellProps) => {
  return (
    <AccordionContent>
      <Button
        className="flex w-full justify-start bg-transparent text-center text-black hover:bg-black/10 hover:text-previsionorange"
        asChild
      >
        <Link
          /* @ts-expect-error --- Tanstack router dosent like variable path */
          to={path}
          activeProps={{ className: 'text-previsionorange bg-previsionorange/10' }}
        >
          <Icon className="mr-4 min-h-[20px] min-w-[20px]" size={24} />
          <p className="w-full text-left">{title}</p>
        </Link>
      </Button>
    </AccordionContent>
  );
};
