import { format } from 'date-fns';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { SchoolRecordEnforced } from '@/types/types';

import { buildTimeline } from './timelineBuilder';

const Timeline = ({ data }: { data: SchoolRecordEnforced }) => {
  const timeline = buildTimeline(data).reverse();
  const lastOrder = timeline[0]?.order || 0;

  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <h3 className="text-xl font-bold text-previsionblue">Timeline</h3>
      <Accordion type="multiple" defaultValue={[lastOrder.toString()]}>
        {timeline.map((event) => (
          <AccordionItem value={event.order.toString()}>
            <AccordionTrigger className="gap-x-2 text-left font-normal hover:no-underline">
              <p>
                <span className="font-medium">
                  {event.order}. {format(event.date, 'dd/MM/yyyy HH:mm:ss')}
                </span>
                - {event.event}
              </p>
            </AccordionTrigger>
            <AccordionContent>
              <p className="text-sm text-black/60">{event.description}</p>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default Timeline;
