import { SchoolRecord } from '@/types/types';

export const positionFinder = (pendingInscription: SchoolRecord | null) => {
  const formStep = pendingInscription?.formProgress || 1;

  if (formStep === 8) return '/steps/eight';

  if (formStep === 7) return '/steps/eight';

  if (formStep === 6) return '/steps/seven';

  if (formStep === 5) return '/steps/six';

  if (formStep === 4) return '/steps/five';

  if (formStep === 3) return '/steps/four';

  if (formStep === 2) return '/steps/three';

  if (formStep === 1) return '/steps/two';

  return '/steps/one';
};
