import { createFileRoute, redirect } from '@tanstack/react-router';
import ls from 'localstorage-slim';

import { NotFoundPage } from '@/pages/404';
import { ScolarityInfomations } from '@/pages/inscriptions/scolarity-infomations';
import { SchoolRecord } from '@/types/types';
import { wipeLocalStorage } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/steps/three')({
  beforeLoad: () => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (!pendingInscription) {
      throw redirect({ to: '/steps/one' });
    }

    if (
      pendingInscription &&
      pendingInscription.formProgress &&
      pendingInscription.formProgress === 8
    ) {
      throw redirect({
        to: '/steps/eight',
        search: {
          isFromCallbackPayment: false,
        },
      });
    }

    if (!pendingInscription.formProgress || pendingInscription.formProgress + 1 < 3) {
      throw redirect({
        to: '/steps/two',
      });
    }
  },
  component: () => <ScolarityInfomations />,
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Réessayer"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
      isOnTop
      resetHeader
      displayAdvices
      buttonFunction={wipeLocalStorage}
    />
  ),
});
