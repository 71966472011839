import { createFileRoute } from '@tanstack/react-router';

import { NotFoundPage } from '@/pages/404';
import { FormulesTablePage } from '@/pages/admin/formules/tablePage';
import { LoaderPage } from '@/pages/loader';
import { formulesBuilder } from '@/utils/dataBuilder';

export const Route = createFileRoute('/admin/formules/published')({
  loader: async () => {
    const datas = await formulesBuilder('published');
    return datas;
  },
  pendingComponent: () => <LoaderPage />,
  component: () => (
    <FormulesTablePage from="/admin/formules/published" title="Formules publiées" />
  ),
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
