import { cn } from '@/lib/utils';

export const LoaderPage = ({
  bgClassName,
  pointClassName,
}: {
  bgClassName?: string;
  pointClassName?: string;
}) => {
  return (
    <section
      className={cn(
        'flex min-h-[calc(100vh-5rem)] w-full items-center justify-center bg-previsiongrey md:w-[calc(100%-20rem)]',
        bgClassName,
      )}
    >
      <div className="flex flex-row gap-2">
        <div
          className={cn(
            'h-4 w-4 animate-bounce rounded-full bg-previsionorange',
            pointClassName,
          )}
        />
        <div
          className={cn(
            'h-4 w-4 animate-bounce rounded-full bg-previsionorange [animation-delay:-.3s]',
            pointClassName,
          )}
        />
        <div
          className={cn(
            'h-4 w-4 animate-bounce rounded-full bg-previsionorange [animation-delay:-.5s]',
            pointClassName,
          )}
        />
      </div>
    </section>
  );
};
