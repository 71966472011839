import parse from 'html-react-parser';

import { Switch } from '@/components/ui/switch';

export const FormuleSelectCard = ({
  title,
  price,
  description,
  currentSelectedTitle,
  selectFunction,
}: {
  title: string;
  price: string;
  description: string;
  currentSelectedTitle: string;
  selectFunction: () => void;
}) => {
  return (
    <div className="font-base text-black">
      <div className="flex w-full flex-row items-center justify-around">
        <Switch
          onCheckedChange={(checked) => checked && selectFunction()}
          checked={currentSelectedTitle === title}
          className="data-[state=checked]:bg-previsionorange"
        />
        <div className="ml-4 flex w-full flex-row gap-4">
          <p className="break-word w-4/5 font-medium">{title}</p>
          <p className="w-1/5 text-nowrap text-right font-medium">{price} €</p>
        </div>
      </div>
      <div className="prose prose-sm prose-stone my-4 prose-h2:my-0 prose-h2:py-0 prose-p:m-0 prose-p:p-0 prose-blockquote:my-0 prose-blockquote:py-0 prose-ol:my-0 prose-ol:py-0 prose-ul:my-0 prose-ul:py-0 prose-li:my-0 prose-li:py-0 md:mx-20">
        {parse(description ?? '')}
      </div>
    </div>
  );
};
