import { createFileRoute, redirect } from '@tanstack/react-router';

import { NotFoundPage } from '@/pages/404';

export const Route = createFileRoute('/admin/settings')({
  beforeLoad: () => {
    if (
      location.pathname === '/admin/settings' ||
      location.pathname === '/admin/settings/'
    ) {
      throw redirect({ to: '/admin/settings/formules' });
    }
  },
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
