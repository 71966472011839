import { FileType } from '@/types/types';

export const fileTitleConverter = (key: FileType) => {
  switch (key) {
    case 'identityPicture':
      return "Photo d'identité";
    case 'identityDocument':
      return "Pièce d'identité";
    case 'scolarityElevenGradeReportOne':
      return 'Première - Trimestre 1';
    case 'scolarityElevenGradeReportTwo':
      return 'Première - Trimestre 2';
    case 'scolarityElevenGradeReportThree':
      return 'Première - Trimestre 3';
    case 'scolarityReportOne':
      return 'Terminale - Trimestre 1';
    case 'scolarityReportTwo':
      return 'Terminale - Trimestre 2';
    case 'scolarityReportThree':
      return 'Terminale - Trimestre 3';
    case 'HighSchoolDiplomaGrades':
      return 'Relevé de notes';
    default:
      return key ?? 'Fichier inconnu';
  }
};

export const paymentTitleConverter = (key: string) => {
  switch (key) {
    case 'september':
      return 'Septembre';
    case 'october':
      return 'Octobre';
    case 'november':
      return 'Novembre';
    case 'december':
      return 'Décembre';
    case 'january':
      return 'Janvier';
    case 'february':
      return 'Février';
    case 'march':
      return 'Mars';
    case 'april':
      return 'Avril';
    case 'may':
      return 'Mai';
    case 'june':
      return 'Juin';
    case 'accompte':
      return 'Accompte';
    case 'total':
      return 'Total';
    default:
      return key;
  }
};
