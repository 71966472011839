import { Link } from '@tanstack/react-router';
import clsx from 'clsx';

type StepCellProps = {
  position: number;
  title: string;
  path: string;
  disabled?: boolean;
};

export const StepCell = ({ position, title, path, disabled = false }: StepCellProps) => {
  return (
    <Link
      disabled={disabled}
      /* @ts-expect-error --- Tanstack router dosent like not hard string */
      to={`/${path}`}
      activeProps={{ className: 'text-previsionorange' }}
      className={clsx(
        'flex justify-start space-x-4 text-xl',
        disabled && 'cursor-not-allowed opacity-50',
      )}
    >
      <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-previsionorange text-center text-previsionorange">
        <p className="m-4">{position}</p>
      </div>
      <p className="my-auto">{title}</p>
    </Link>
  );
};
