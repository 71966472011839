import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Link, useLoaderData } from '@tanstack/react-router';
import clsx from 'clsx';
import { LoaderCircle } from 'lucide-react';
import { FC, FormEvent, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Skeleton } from '@/components/ui/skeleton';
import { RouteSevenData } from '@/pages/inscriptions/payment';

export const CheckoutForm: FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const { buyerInfos }: RouteSevenData = useLoaderData({
    from: '/steps/seven',
  });

  const [isPaymentFieldReady, setIsPaymentFieldReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [buyerName, setBuyerName] = useState(buyerInfos.name);
  const [buyerEmail, setBuyerEmail] = useState(buyerInfos.email);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!buyerName || buyerName.length < 3 || !buyerEmail) {
      setIsSubmitting(false);
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + '/callbacks/payment',
        payment_method_data: {
          billing_details: {
            name: buyerName,
            email: buyerEmail,
          },
        },
      },
    });

    if (result.error) {
      console.error(result.error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} className="h-fit">
      {!isPaymentFieldReady && (
        <div className="space-y-4">
          <Skeleton className="h-[40px] w-full rounded-md" />
          <Skeleton className="h-[40px] w-full rounded-md" />
          <Skeleton className="h-[40px] w-full rounded-md" />
          <div className="flex justify-between space-x-3">
            <Skeleton className="h-[40px] w-1/2 rounded-md" />
            <Skeleton className="h-[40px] w-1/2 rounded-md" />
          </div>
          <Skeleton className="h-[40px] w-full rounded-md" />
        </div>
      )}
      <div className={clsx(isPaymentFieldReady ? 'block' : 'hidden', 'mt-4')}>
        <div className="my-2">
          <Label htmlFor="buyerName">Nom du titulaire de la carte</Label>
          <Input
            id="buyerName"
            type="text"
            placeholder=""
            value={buyerName}
            onChange={(e) => setBuyerName(e.target.value)}
          />
        </div>
        <div className="my-2">
          <Label htmlFor="buyerEmail">Email du parent</Label>
          <Input
            id="buyerEmail"
            type="email"
            disabled
            placeholder=""
            value={buyerEmail}
            onChange={(e) => setBuyerEmail(e.target.value)}
            className="disabled:bg-gray-100 disabled:opacity-100"
            title="Vous ne pouvez pas changer ce champ"
          />
        </div>
        <PaymentElement
          onReady={() => {
            setIsPaymentFieldReady(true);
          }}
        />
      </div>

      <div
        className={clsx(
          'mt-4 flex justify-between',
          isPaymentFieldReady ? 'block' : 'hidden',
        )}
      >
        <Link to={`/steps/six`}>
          <Button className="w-32" type="button" variant="outlinedBlue">
            Précédent
          </Button>
        </Link>
        <Button
          className="w-32"
          type="submit"
          variant="squaredBlue"
          disabled={
            isSubmitting || !isPaymentFieldReady || !stripe || buyerName.length < 3
          }
        >
          {isSubmitting ? <LoaderCircle className="animate-spin" /> : 'Payer'}
        </Button>
      </div>
    </form>
  );
};
