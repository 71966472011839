import { HasPosition } from '@/types/types';

export const sortByPosition = <T extends HasPosition>(list: T[]): T[] => {
  return list.sort((a, b) => {
    if (a.position === undefined) a.position = null;
    if (b.position === undefined) b.position = null;

    if (a.position === null) return 1; // Si la position de a est null, a vient après b
    if (b.position === null) return -1; // Si la position de b est null, b vient avant a

    const positionA = parseInt(a.position);
    const positionB = parseInt(b.position);
    return positionA - positionB;
  });
};
