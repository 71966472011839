import { useLoaderData } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { CustomTextEditor } from '@/components/text-editor/Editor';
import { SectionSubtitle, SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import { saveRightPanelContent } from '@/data/setters/admin';

const editorModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ color: [] }, { background: [] }],
    ['clean'],
  ],
};

export const SettingsFormPage = () => {
  const { content } = useLoaderData({
    from: '/admin/settings/form',
  });

  const [editorValue, setEditorValue] = useState<string>(content ?? '');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const resetTextEditor = () => {
    setEditorValue('');
  };

  const saveTextEditor = async () => {
    setIsSubmitting(true);
    try {
      await saveRightPanelContent(editorValue);
      toast('Les modifications ont bien été enregistrées');
    } catch (error) {
      console.error('Error updating document: ', error);
      toast("Une erreur est survenue lors de l'enregistrement des modifications");
    }
    setIsSubmitting(false);
  };

  return (
    <section className="min-h-[calc(100vh-5rem)] w-[100vw] bg-previsiongrey p-2 md:w-[calc(100%-20rem)]">
      <SectionTitle title={'Paramètres - Formulaire'} />
      <div className="my-4 flex min-h-80 flex-col rounded-sm bg-white px-4">
        <SectionSubtitle title={'Contenu de la section droite'} />
        <div className="h-60">
          <CustomTextEditor
            value={editorValue}
            setValue={setEditorValue}
            modules={editorModules}
          />
        </div>
        <div className="my-2 flex justify-between">
          <Button
            className="w-32"
            type="button"
            variant="dashboardOutlinedBlue"
            onClick={resetTextEditor}
          >
            Réinitialiser
          </Button>

          <Button
            className="w-32"
            type="submit"
            variant="dashboardBlue"
            onClick={saveTextEditor}
            disabled={isSubmitting}
          >
            {isSubmitting ? <LoaderCircle className="animate-spin" /> : 'Enregistrer'}
          </Button>
        </div>
      </div>
    </section>
  );
};
