import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLoaderData } from '@tanstack/react-router';
import { useMemo } from 'react';

import { CheckoutForm } from '@/components/checkout-form';
import { SectionTitle } from '@/components/titles';
import { LoaderPage } from '@/pages/loader';

import { stripePublicKey } from '../../../firebaseCredentials.ts';

// Not a complete type
interface PaymentIntent {
  client_secret: string;
}

export interface RouteSevenData {
  paymentIntent: PaymentIntent;
  buyerInfos: {
    name: string;
    email: string;
  };
}

export const PaymentPage = () => {
  const stripePromise = useMemo(() => loadStripe(stripePublicKey), []);

  const { paymentIntent }: RouteSevenData = useLoaderData({
    from: '/steps/seven',
  });

  if (!paymentIntent) {
    return <LoaderPage />;
  }

  const options = {
    clientSecret: paymentIntent.client_secret,
  };

  return (
    <div className="h-fit w-11/12 bg-white px-7 py-4 text-black/60 lg:w-2/3">
      <SectionTitle title="Acompte" />
      <p className="mb-4">
        Afin de finaliser votre dossier merci de règler l'acompte de 190€.
      </p>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};
