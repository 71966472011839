import { useLoaderData } from '@tanstack/react-router';

import { formulesColumn } from '@/components/data-table/columns';
import { FormulesDataTable } from '@/components/data-table/table';
import { SectionTitle } from '@/components/titles';
import { Formule } from '@/types/types';

export const FormulesTablePage = ({ from, title }: { from: string; title: string }) => {
  const {
    formules,
    yearList,
    formuleList,
  }: {
    formules: Formule[];
    yearList: string[];
    formuleList: string[];
  } = useLoaderData({
    // @ts-expect-error -- Type is coming from router, find how to type it
    from,
  });

  return (
    <section className="min-h-[calc(100vh-5rem)] w-full bg-previsiongrey p-2 md:w-[calc(100%-20rem)]">
      <SectionTitle title={title} />
      <FormulesDataTable
        columns={formulesColumn}
        data={formules}
        yearList={yearList}
        formuleList={formuleList}
      />
    </section>
  );
};
