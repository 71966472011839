import { createFileRoute } from '@tanstack/react-router';

import { getScholarYear, getScolarityTypes } from '@/data/getters/admin';
import { NotFoundPage } from '@/pages/404';
import { SettingsFormulePage } from '@/pages/admin/settings/formules';
import { LoaderPage } from '@/pages/loader';
import { FirebaseCombobox } from '@/types/types';
import { sortByPosition } from '@/utils/sortLists';

export const Route = createFileRoute('/admin/settings/formules')({
  loader: async () => {
    let years: FirebaseCombobox[] = await getScholarYear(true);
    let types: FirebaseCombobox[] = await getScolarityTypes(true);

    //If years is a array of objects, then we can sort it by position
    if (years && years.length > 0) {
      years = sortByPosition(years);
    } else if (!years || years.length === 0) {
      years = [];
    }

    if (types && types.length > 0) {
      types = sortByPosition(types);
    } else if (!types || types.length === 0) {
      types = [];
    }

    return { years, types };
  },
  pendingComponent: () => <LoaderPage bgClassName="h-screen w-full" />,
  component: () => <SettingsFormulePage />,
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
