import { createFileRoute, redirect } from '@tanstack/react-router';

import { auth } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { AdminLayout } from '@/pages/admin/layout';

export const Route = createFileRoute('/admin')({
  beforeLoad: async ({ location }) => {
    const adminPathRegex = /^\/admin\/.*/;
    const isAdminPath = adminPathRegex.test(location.pathname);

    await auth.authStateReady();
    if (!auth.currentUser) {
      if (isAdminPath) {
        throw redirect({
          to: '/login',
          search: {
            origin: location.pathname,
          },
        });
      }

      throw redirect({
        to: '/login',
      });
    }
    if (auth.currentUser.isAnonymous) {
      await auth.signOut();

      if (isAdminPath) {
        throw redirect({
          to: '/login',
          search: {
            origin: location.pathname,
          },
        });
      }

      throw redirect({
        to: '/login',
      });
    }

    if (location.pathname === '/admin' || location.pathname === '/admin/') {
      throw redirect({
        to: '/admin/inscriptions/pending',
      });
    }
  },
  component: () => <AdminLayout />,
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
});
