import { createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { httpsCallable } from 'firebase/functions';
import ls from 'localstorage-slim';

import { functions } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { PaymentPage } from '@/pages/inscriptions/payment';
import { LoaderPage } from '@/pages/loader';
import { SchoolRecord } from '@/types/types';
import { wipeLocalStorage } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/steps/seven')({
  beforeLoad: () => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (!pendingInscription) {
      throw redirect({ to: '/steps/one' });
    }

    if (
      pendingInscription &&
      pendingInscription.formProgress &&
      pendingInscription.formProgress === 8
    ) {
      throw redirect({
        to: '/steps/eight',
        search: {
          isFromCallbackPayment: false,
        },
      });
    }

    if (!pendingInscription.formProgress || pendingInscription.formProgress + 1 < 7) {
      throw redirect({
        to: '/steps/six',
      });
    }
  },
  loader: async () => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (!pendingInscription) {
      throw notFound();
    }

    const createPayment = httpsCallable(functions, 'createPayment');

    try {
      let createPaymentData: {
        studentName: string;
        recordId: string;
        studentEmail: string | undefined;
        existingPaymentIntentId?: string;
      } = {
        studentName:
          pendingInscription.student?.lastname +
          ' ' +
          pendingInscription.student?.firstname,
        recordId: pendingInscription.recordId,
        studentEmail: pendingInscription.student?.contact.email,
      };

      if (pendingInscription.paymentIntentId) {
        createPaymentData = {
          ...createPaymentData,
          existingPaymentIntentId: pendingInscription.paymentIntentId,
        };
      }

      const result = await createPayment(createPaymentData);

      if (result.data === undefined) throw new Error('No data returned');

      if (
        result.data &&
        // @ts-expect-error -- The type is coming from firebase and its not complete
        result.data.id &&
        !pendingInscription.paymentIntentId
      ) {
        // @ts-expect-error -- The type is coming from firebase and its not complete
        pendingInscription.paymentIntentId = result.data.id;
        ls.set(LOCALSTORAGE_INSRIPTION_KEY, pendingInscription);
      }

      return {
        paymentIntent: result.data,
        buyerInfos: {
          name:
            pendingInscription.referent?.lastname +
            ' ' +
            pendingInscription.referent?.firstname,
          email: pendingInscription.referent?.contact.email,
        },
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  component: () => <PaymentPage />,
  pendingComponent: () => (
    <LoaderPage
      bgClassName="bg-gray-100 h-screen w-full"
      pointClassName="bg-previsionblue"
    />
  ),
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Réessayer"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
      isOnTop
      resetHeader
      displayAdvices
      buttonFunction={wipeLocalStorage}
    />
  ),
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Retour à l'accueil"
      buttonLink="/"
      description="La page que vous cherchez n'existe pas."
      isOnTop
      resetHeader
    />
  ),
});
