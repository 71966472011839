import { useNavigate } from '@tanstack/react-router';
import { FileCheck } from 'lucide-react';

import { SectionSubtitle, SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import { nukeLocalData } from '@/utils/cleaner';
import { PREVISION_MAIL } from '@/utils/constants';

export const ConfirmationPage = () => {
  const navigate = useNavigate();

  return (
    <section className="font-base h-fit space-y-6 bg-white px-7 py-4 text-black/75 lg:w-2/3">
      <SectionTitle title="Confirmation" />
      <div className="space-y-2">
        <SectionSubtitle title="Merci beaucoup !" />
        <p>
          Votre inscription à bien été prise en compte et sera traitée dans les plus brefs
          délais par notre équipe pédagogique.
        </p>
        <div className="flex justify-center">
          <FileCheck size={172} className="text-previsionblue" />
        </div>
        <p>
          Vous allez recevoir un récapitulatif de votre inscription par mail.
          <br />
          Si jamais vous ne recevez rien, n'hésitez pas à nous le signaler par mail à{' '}
          <a
            className="text-previsionorange transition-all hover:opacity-60"
            href={`mailto:${PREVISION_MAIL}`}
          >
            {PREVISION_MAIL}
          </a>
        </p>
      </div>
      <div className="flex justify-center">
        <Button variant="outlinedBlue" onClick={() => nukeLocalData(navigate)}>
          Rédiger un nouveau dossier
        </Button>
      </div>
    </section>
  );
};
