import { createFileRoute, redirect } from '@tanstack/react-router';
import { getDownloadURL, ref } from 'firebase/storage';
import ls from 'localstorage-slim';

import { storage } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { TermsAndConditions } from '@/pages/inscriptions/terms-conditions';
import { LoaderPage } from '@/pages/loader';
import { SchoolRecord } from '@/types/types';
import { wipeLocalStorage } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/steps/six')({
  beforeLoad: () => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (!pendingInscription) {
      throw redirect({ to: '/steps/one' });
    }

    if (
      pendingInscription &&
      pendingInscription.formProgress &&
      pendingInscription.formProgress === 8
    ) {
      throw redirect({
        to: '/steps/eight',
        search: {
          isFromCallbackPayment: false,
        },
      });
    }

    if (!pendingInscription.formProgress || pendingInscription.formProgress + 1 < 6) {
      throw redirect({
        to: '/steps/five',
      });
    }
  },
  loader: async () => {
    const gtcRef = ref(storage, '/public/CGV_PREVISION.pdf');
    const scholarityRulesRef = ref(storage, '/public/REGLEMENT_INTERIEUR_PREVISION.pdf');
    const imageUseRef = ref(storage, '/public/CLAUSE_DE_DROIT_A_LIMAGE_PREVISION.pdf');

    const gtcUrl = await getDownloadURL(gtcRef);
    const scholarityRulesUrl = await getDownloadURL(scholarityRulesRef);
    const imageUseUrl = await getDownloadURL(imageUseRef);

    return {
      gtcUrl,
      scholarityRulesUrl,
      imageUseUrl,
    };
  },
  pendingComponent: () => <LoaderPage />,
  component: () => <TermsAndConditions />,
  errorComponent: () => (
    <NotFoundPage
      primaryColor="previsionblue"
      buttonText="Réessayer"
      buttonLink="/steps/one"
      description="Le site a rencontré une erreur."
      isOnTop
      resetHeader
      displayAdvices
      buttonFunction={wipeLocalStorage}
    />
  ),
});
