import { SquarePen } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { DataCell } from '@/components/ui/record-cell';
import { Separator } from '@/components/ui/separator';
import { Scolarity } from '@/types/types';

export const PastScolarityBlock = ({
  infos,
  triggerEdit,
}: {
  infos: Scolarity;
  triggerEdit: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex flex-wrap justify-between">
        <h3 className="text-xl font-bold text-previsionblue">BACCALAURÉAT</h3>
        <Button
          variant="outline"
          className="w-auto"
          onClick={() => {
            triggerEdit('scolarity');
          }}
        >
          <SquarePen size={24} />
        </Button>
      </div>
      <div className="space-y-1">
        <section className="w-auto">
          <DataCell
            title="Spécialités"
            subtitle="Spécialités - Terminale"
            value={
              infos.twelveGradesSpecialities
                ? `• ${infos.twelveGradesSpecialities.one}`
                : '• Non renseignée'
            }
          />
          <DataCell
            value={
              infos.twelveGradesSpecialities
                ? `• ${infos.twelveGradesSpecialities.two}`
                : '• Non renseignée'
            }
          />

          <DataCell
            subtitle="Spécialité abandonnée en Première"
            value={
              infos.twelveGradesSpecialities && infos.twelveGradesSpecialities.dropped
                ? `• ${infos.twelveGradesSpecialities.dropped}`
                : '• Non renseignée ou N/A'
            }
          />
          <DataCell
            subtitle="Option"
            value={
              infos.twelveGradesSpecialities && infos.twelveGradesSpecialities.variant
                ? `• ${infos.twelveGradesSpecialities.variant}`
                : '• Non renseignée ou N/A'
            }
          />
        </section>
      </div>
      <Separator className="mx-auto my-1 w-5/12 bg-black/30" />
      <DataCell
        title="Motivation"
        mode="textarea"
        value={infos.motivation ? infos.motivation : 'Non renseigné'}
        valueClassName="h-24 text-sm font-light"
      />
      {infos.diplomaSpecialities && infos.diplomaSpecialities.length > 0 && (
        <>
          <Separator className="mx-auto my-1 w-5/12 bg-black/30" />
          <Accordion type="single" collapsible className="mx-2">
            <AccordionItem value="item-1" className="border-b-0">
              <AccordionTrigger className="text-small py-2 font-medium text-gray-500 hover:no-underline">
                Anciennes données
              </AccordionTrigger>
              <AccordionContent>
                <section className="w-auto">
                  {infos.diplomaSpecialities.map((speciality, index) => {
                    if (index === 0)
                      return (
                        <DataCell
                          key={index}
                          subtitle="Spécialités de Première"
                          value={speciality ? `• ${speciality}` : 'Non renseignée'}
                          valueClassName="text-sm font-normal"
                        />
                      );
                    return (
                      <DataCell
                        key={index}
                        value={speciality ? `• ${speciality}` : null}
                        valueClassName="text-sm font-normal"
                      />
                    );
                  })}
                </section>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </>
      )}
    </div>
  );
};
