import { createFileRoute, redirect } from '@tanstack/react-router';
import { httpsCallable } from 'firebase/functions';
import ls from 'localstorage-slim';

import { stepSevenSubmit } from '@/data/form-handlers';
import { functions } from '@/lib/firebase.ts';
import { LoaderPage } from '@/pages/loader';
import { SchoolRecord } from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/callbacks/payment')({
  beforeLoad: async ({ location }) => {
    const pendingInscription: SchoolRecord | null =
      ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

    if (!pendingInscription) {
      console.error('Aucun utilisateur trouvé');
      console.error('Redirection vers la dernière étape accessible');
      throw redirect({ to: '/steps' });
    }

    if (pendingInscription && pendingInscription.formProgress < 6) {
      console.error("L'utilisateur n'a pas accèdé à l'étape 7");
      console.error('Redirection vers la dernière étape accessible');
      throw redirect({
        to: '/steps',
      });
    }

    if (
      pendingInscription &&
      pendingInscription.formProgress &&
      pendingInscription.formProgress === 8
    ) {
      console.error("L'utilisateur a déja accèdé à l'étape 8");
      console.error('Redirection vers la dernière étape du formulaire');
      throw redirect({
        to: '/steps/eight',
        search: {
          isFromCallbackPayment: false,
        },
      });
    }

    const paymentIntentId = (location.search as { payment_intent: string })
      .payment_intent;

    if (!paymentIntentId) {
      console.error('Le paiementIntentId est manquant');
      throw redirect({
        to: '/steps',
      });
    }
    const checkPayment = httpsCallable(functions, 'checkPayment');

    const response = await checkPayment({ paymentIntentId });

    if (response) {
      // @ts-expect-error -- The type is coming from firebase and its not complete
      const res = await stepSevenSubmit(response.data);

      if (!res) {
        console.error('Erreur lors de la sauvegarde des données');
        throw redirect({
          to: '/steps/seven',
        });
      }

      const sendEmailToUser = httpsCallable(functions, 'sendEmailToUser');

      const user: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

      if (!user) {
        console.error('Aucun utilisateur trouvé');
        throw redirect({
          to: '/steps',
        });
      }

      const referentEmail = user.referent?.contact.email;
      const referentName = `${user.referent?.firstname} ${user.referent?.lastname}`;
      const studentEmail = user.student?.contact.email;
      const studentName = `${user.student?.firstname} ${user.student?.lastname}`;

      if (!referentEmail || !referentName || !studentEmail || !studentName) {
        console.error('Aucun email trouvé');
        console.error('Redirection vers la dernière étape du formulaire');
        throw redirect({
          to: '/steps/eight',
          search: {
            isFromCallbackPayment: true,
          },
        });
      }

      await sendEmailToUser({
        recipient: {
          referentEmail,
          referentName,
          studentEmail,
          studentName,
        },
        templateName: 'depositPaid',
      }).catch((error) => {
        console.error(`Erreur lors de l'envoi de l'email de confirmation: ${error}`);
      });

      throw redirect({
        to: '/steps/eight',
        search: {
          isFromCallbackPayment: true,
        },
        throw: true,
      });
    } else {
      console.error("Le paiement n'a pas été validé");
      console.error('Redirection vers la dernière étape accessible');
      redirect({
        to: '/steps/seven',
        throw: true,
      });
    }
  },
  pendingComponent: () => (
    <LoaderPage
      bgClassName="bg-gray-100 h-screen w-full"
      pointClassName="bg-previsionblue"
    />
  ),
  component: () => (
    <LoaderPage
      bgClassName="bg-gray-100 h-screen w-full"
      pointClassName="bg-previsionblue"
    />
  ),
});
