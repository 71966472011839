import {
  Archive,
  Ban,
  Check,
  CirclePlus,
  Clock3,
  CreditCard,
  EyeOff,
  FileCog,
  FolderCog,
} from 'lucide-react';

export const MAX_FILE_SIZE = 5000000;
export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
];

export const inscriptionsLinks = [
  {
    title: 'Inscriptions payées',
    path: '/admin/inscriptions/done',
    icon: CreditCard,
  },
  {
    title: 'Inscriptions en cours',
    path: '/admin/inscriptions/pending',
    icon: Clock3,
  },
  {
    title: 'Inscriptions archivées',
    path: '/admin/inscriptions/archived',
    icon: Archive,
  },
  {
    title: 'Inscriptions annulées',
    path: '/admin/inscriptions/cancelled',
    icon: Ban,
  },
];

export const formulesLinks = [
  {
    title: 'Formules publiées',
    path: '/admin/formules/published',
    icon: Check,
  },
  {
    title: 'Formule non publiées',
    path: '/admin/formules/unpublished',
    icon: EyeOff,
  },
  {
    title: 'Formule archivées',
    path: '/admin/formules/archived',
    icon: Archive,
  },
  {
    title: 'Ajouter une formule',
    path: '/admin/formules/new',
    icon: CirclePlus,
  },
];

export const settingsLinks = [
  {
    title: 'Paramètres - Formules',
    path: '/admin/settings/formules',
    icon: FolderCog,
  },
  {
    title: 'Paramètres - Formulaire',
    path: '/admin/settings/form',
    icon: FileCog,
  },
];

export const LOCALSTORAGE_INSRIPTION_KEY = 'pendingInscription';

export const FORMULE_INFORMATIONS_KEY = 'formuleInformations';

export const PREVISION_MAIL = 'contact@prepa-prevision.fr';

export const TABLE_ROWS_PER_PAGE_OPTIONS = [20, 50, 100, 200, 500];
