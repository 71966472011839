import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { db } from '@/lib/firebase';

export const getScholarYear = async (onlyAvailable = false) => {
  const yearsRef = doc(db, 'constants', 'schoolYears');
  const query = await getDoc(yearsRef);
  let data = null;

  query.exists() && (data = query.data().years);

  if (!data) return [];

  if (onlyAvailable) {
    return data.filter((year: { isAvailable: boolean }) => year.isAvailable);
  }

  return data;
};

export const getScolarityTypes = async (onlyAvailable = false) => {
  const colRef = doc(db, 'constants', 'scolarityTypes');
  const query = await getDoc(colRef);
  let data = null;

  query.exists() && (data = query.data().types);

  if (!data) return [];

  if (onlyAvailable) {
    return data.filter((type: { isAvailable: boolean }) => type.isAvailable);
  }

  return data;
};

export const getFormule = async ({ status }: { status?: string }) => {
  const path = status ? status : 'unpublished';

  const colRef = collection(db, `formules`);
  const querySnapshot = await getDocs(colRef);

  const data = querySnapshot.docs.map((doc) => {
    if (!doc.exists()) return null;

    if (doc.id === path) {
      return doc.data();
    }

    return doc.data();
  });

  return data.length > 0 ? (data.length == 1 ? data[0] : data) : null;
};

export const getRightPanelContent = async () => {
  try {
    const constantsRef = doc(db, 'constants', 'rightPanelContent');
    const query = await getDoc(constantsRef);

    return query.data() as { content: string };
  } catch (error) {
    console.error('Error getting document: ', error);
  }
};
