import { SquarePen } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { DataCell } from '@/components/ui/record-cell';
import { Formule } from '@/types/types';

export const FormulesInformations = ({
  infos,
  triggerEdit,
}: {
  infos: Formule;
  triggerEdit: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold text-previsionblue">FORMULES</h3>
        <Button
          variant="outline"
          className="w-auto"
          onClick={() => {
            triggerEdit('formule');
          }}
        >
          <SquarePen size={24} />
        </Button>
      </div>
      <div className="flex flex-wrap">
        <DataCell title="Année" value={infos.year} />
        <DataCell title="Préparation" value={infos.type} />
        <DataCell title="Intitulé" value={infos.title} />
        <DataCell title="Prix" value={`${infos.price} €`} />
      </div>
    </div>
  );
};
