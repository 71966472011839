import { createFileRoute } from '@tanstack/react-router';

import { getRightPanelContent } from '@/data/getters/admin';
import { NotFoundPage } from '@/pages/404';
import { SettingsFormPage } from '@/pages/admin/settings/form';
import { LoaderPage } from '@/pages/loader';

export const Route = createFileRoute('/admin/settings/form')({
  loader: async () => {
    const rightPanel: { content: string } | null = (await getRightPanelContent()) || null;
    return {
      content: rightPanel ? (rightPanel as { content: string }).content : '',
    };
  },
  pendingComponent: () => <LoaderPage bgClassName="h-screen w-full" />,
  component: () => <SettingsFormPage />,
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
