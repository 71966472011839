import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { toast } from 'sonner';
import { z } from 'zod';

import { db } from '@/lib/firebase';
import { FirebaseCombobox, Formule } from '@/types/types';

import { addFormuleSchema } from '../forms-schema';
import { getScholarYear, getScolarityTypes } from '../getters/admin';
import { saveDocumentToFirebase } from '../utils';

const checkIfYearExists = async (
  year: string,
): Promise<[boolean, FirebaseCombobox[] | null, FirebaseCombobox | null]> => {
  const years = await getScholarYear();

  if (!years) return [false, null, null];

  const yearExists = years.find(
    (y: { label: string }) => y.label === year.replace('U+2215', '/'),
  );
  if (!yearExists) return [false, years, null];

  const index = years.findIndex(
    (y: { label: string }) => y.label === year.replace('U+2215', '/'),
  );

  return [true, years, years[index]];
};

const checkIfScholarityTypeExists = async (
  type: string,
): Promise<[boolean, FirebaseCombobox[] | null, FirebaseCombobox | null]> => {
  const types = await getScolarityTypes();

  if (!types) return [false, null, null];

  const typeExists = types.find(
    (y: { label: string }) => y.label === type.replace('U+2215', '/'),
  );
  if (!typeExists) return [false, types, null];

  const index = types.findIndex(
    (y: { label: string }) => y.label === type.replace('U+2215', '/'),
  );

  return [true, types, types[index]];
};

export const addYear = async (
  year: string,
  position: string | null = null,
  warnAlreadyExists: boolean = true,
) => {
  const doesExist = await checkIfYearExists(year);
  const yearExists = doesExist[0];
  const years = doesExist[1] || [];
  const foundYear = doesExist[2];

  if (yearExists && foundYear && !foundYear.isAvailable) {
    const indexToUpdate = years.findIndex((y) => y.id === foundYear.id);
    if (indexToUpdate !== -1) {
      if (years[indexToUpdate]) {
        years[indexToUpdate].isAvailable = true;

        if (years[indexToUpdate].id === null || years[indexToUpdate].id === undefined) {
          let tempID = years.length + 1;

          while (years.some((y) => y.id === tempID.toString())) {
            tempID++;
          }

          years[indexToUpdate].id = tempID.toString();
        }
      }

      try {
        const userRef = collection(db, `constants`);
        const docRef = doc(userRef, `schoolYears`);
        await setDoc(docRef, { years });
      } catch (error) {
        console.error('Error updating document: ', error);
        toast("Erreur lors de la mise à jour de l'année scolaire");
        return false;
      }
    }
    return true;
  } else if (!yearExists) {
    try {
      const userRef = collection(db, `constants`);
      const docRef = doc(userRef, `schoolYears`);

      let tempID = years.length + 1;
      while (years.some((y) => y.id === tempID.toString())) {
        tempID++;
      }

      await setDoc(docRef, {
        years: [
          ...years,
          {
            label: year.replace('U+2215', '/'),
            value: year.replace('U+2215', '/'),
            isAvailable: true,
            position: position,
            id: tempID.toString(),
          } as FirebaseCombobox,
        ],
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      toast("Erreur lors de l'ajout de l'année scolaire");
      return false;
    }
  } else if (warnAlreadyExists) {
    toast('Cette année scolaire existe déjà');
    return false;
  }
  return true;
};

export const addScholarityType = async (
  type: string,
  position: string | null = null,
  warnAlreadyExists: boolean = true,
) => {
  const doesExist = await checkIfScholarityTypeExists(type);
  const typeExists = doesExist[0];
  const types = doesExist[1] || [];
  const foundType = doesExist[2];

  if (typeExists && foundType && !foundType.isAvailable) {
    const indexToUpdate = types.findIndex((t) => t.id === foundType.id);
    if (indexToUpdate !== -1) {
      if (types[indexToUpdate]) {
        types[indexToUpdate].isAvailable = true;

        if (types[indexToUpdate].id === null || types[indexToUpdate].id === undefined) {
          let tempID = types.length + 1;

          while (types.some((y) => y.id === tempID.toString())) {
            tempID++;
          }

          types[indexToUpdate].id = tempID.toString();
        }
      }

      try {
        const userRef = collection(db, `constants`);
        const docRef = doc(userRef, `scolarityTypes`);
        await setDoc(docRef, { types });
      } catch (error) {
        console.error('Error updating document: ', error);
        toast('Erreur lors de la mise à jour du type de scolarité');
        return false;
      }
    }
    return true;
  } else if (!typeExists) {
    try {
      const userRef = collection(db, `constants`);
      const docRef = doc(userRef, `scolarityTypes`);

      let tempID = types.length + 1;
      while (types.some((y) => y.id === tempID.toString())) {
        tempID++;
      }

      await setDoc(docRef, {
        types: [
          ...types,
          {
            label: type.replace('U+2215', '/'),
            value: type.replace('U+2215', '/'),
            isAvailable: true,
            position: position,
            id: tempID.toString(),
          } as FirebaseCombobox,
        ],
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      toast("Erreur lors de l'ajout du type de scolarité");
      return false;
    }
  } else if (warnAlreadyExists) {
    toast('Ce type de scolarité existe déjà');
    return false;
  }
  return true;
};

export const updateYear = async (
  itemId: string | null,
  year: string,
  position: string | null = null,
  isOnlyPosition: boolean = false,
) => {
  const doesExist = await checkIfYearExists(year);
  const yearExists = doesExist[0];
  const years = doesExist[1] || [];

  if (!yearExists || isOnlyPosition) {
    const clonedYears: FirebaseCombobox[] = years.slice();

    const index = clonedYears.findIndex(
      (y: FirebaseCombobox) => y.id === itemId || y.label === itemId,
    );
    if (clonedYears[index]) {
      clonedYears[index] = {
        ...clonedYears[index],
        value: year,
        label: year,
        position,
      };

      if (clonedYears[index].id === null || clonedYears[index].id === undefined) {
        let tempID = clonedYears.length + 1;

        while (clonedYears.some((y) => y.id === tempID.toString())) {
          tempID++;
        }

        clonedYears[index].id = tempID.toString();
      }
    }
    try {
      await saveDocumentToFirebase('constants', 'schoolYears', { years: clonedYears });
      return true;
    } catch (error) {
      console.error('Error adding document: ', error);
      toast("Erreur lors de l'ajout de l'année scolaire");
      return false;
    }
  } else {
    toast('Cette année scolaire existe déjà');
    return false;
  }
};

export const updateType = async (
  itemId: string | null,
  type: string,
  position: string | null = null,
  isOnlyPosition: boolean = false,
  deepUpdate: boolean = false,
) => {
  const doesExist = await checkIfScholarityTypeExists(type);
  const typeExists = doesExist[0];
  const types = doesExist[1] || [];

  if (!typeExists || isOnlyPosition) {
    const clonedTypes: FirebaseCombobox[] = types.slice();

    const index = clonedTypes.findIndex(
      (t: FirebaseCombobox) => t.id === itemId || t.label === itemId,
    );

    const oldType = clonedTypes[index];

    if (clonedTypes[index]) {
      clonedTypes[index] = {
        ...clonedTypes[index],
        value: type,
        label: type,
        position,
      };

      if (clonedTypes[index].id === null || clonedTypes[index].id === undefined) {
        let tempID = clonedTypes.length + 1;

        while (clonedTypes.some((y) => y.id === tempID.toString())) {
          tempID++;
        }

        clonedTypes[index].id = tempID.toString();
      }
    }

    try {
      await saveDocumentToFirebase('constants', 'scolarityTypes', { types: clonedTypes });

      if (deepUpdate) {
        if (oldType && oldType.label) {
          const formuleRef = collection(db, `formules`);
          const formuleQuery = query(formuleRef, where('type', '==', oldType.label));
          const formuleSnapshot = await getDocs(formuleQuery);

          formuleSnapshot.forEach(async (doc) => {
            await updateDoc(doc.ref, { ...doc.data(), type });
          });
        } else {
          console.error('oldType ou oldType.label est indéfini');
        }
      }

      return true;
    } catch (error) {
      console.error('Error updating document: ', error);
      return false;
    }
  } else {
    toast('Ce type existe déjà');
    return false;
  }
};

export const deleteYear = async (itemId: string | null) => {
  const oldYears = await getScholarYear();
  const clonedYears: FirebaseCombobox[] = oldYears.slice();

  const index = clonedYears.findIndex(
    (t: FirebaseCombobox) => t.id === itemId || t.label === itemId,
  );

  if (clonedYears[index]) {
    clonedYears[index].isAvailable = false;
  }

  try {
    await saveDocumentToFirebase('constants', 'schoolYears', { years: clonedYears });
    return true;
  } catch (error) {
    console.error('Error deleting document: ', error);
    return false;
  }
};

export const deleteType = async (itemId: string | null) => {
  const oldTypes = await getScolarityTypes();
  const clonedTypes: FirebaseCombobox[] = oldTypes.slice();

  const index = clonedTypes.findIndex(
    (t: FirebaseCombobox) => t.id === itemId || t.label === itemId,
  );

  if (clonedTypes[index]) {
    clonedTypes[index].isAvailable = false;
  }

  try {
    await saveDocumentToFirebase('constants', 'scolarityTypes', { types: clonedTypes });
    return true;
  } catch (error) {
    console.error('Error deleting document: ', error);
    return false;
  }
};

export const saveFormule = async (
  data: z.infer<typeof addFormuleSchema> & {
    courseDescription: string;
  },
) => {
  await addYear(data.schoolYear, null, false);
  await addScholarityType(data.courseType, null, false);

  const formuleData: Omit<Formule, 'id'> = {
    status: 'unpublished',
    year: data.schoolYear,
    type: data.courseType,
    title: data.courseTitle,
    description: data.courseDescription,
    price: data.coursePrice,
    reduction: data.courseReduction,
    doesExpire: false,
    expirationDate: null,
    position: data.position || null,
  };

  try {
    const colRef = collection(db, `formules`);
    await addDoc(colRef, formuleData);
    return true;
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }
};

export const saveRightPanelContent = async (content: string) => {
  try {
    await saveDocumentToFirebase(
      'constants',
      'rightPanelContent',
      {
        content,
      },
      { merge: true },
    );
    return true;
  } catch (error) {
    console.error('Error updating document: ', error);
    return false;
  }
};
