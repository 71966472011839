// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useMatchRoute, useNavigate, UseNavigateResult } from '@tanstack/react-router';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { doc, updateDoc } from 'firebase/firestore';
import parse from 'html-react-parser';
import { LoaderCircle } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';

import { DataTablePagination } from '@/components/data-table/table-pagination';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { db } from '@/lib/firebase';
import { Formule } from '@/types/types';
import { TABLE_ROWS_PER_PAGE_OPTIONS } from '@/utils/constants';
import useScreenSize from '@/utils/hooks/useScreenSize';

interface DataTableInscriptionsProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  yearList: string[];
  formuleList: string[];
  recordValidatedSelector?: boolean;
  sortedBy: SortingState;
}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  yearList: string[];
  formuleList: string[];
}

const defaultRowSize =
  TABLE_ROWS_PER_PAGE_OPTIONS.length > 0 ? TABLE_ROWS_PER_PAGE_OPTIONS[0] : 10;

export function InscriptionsDataTable<TData, TValue>({
  columns,
  data,
  yearList,
  formuleList,
  recordValidatedSelector,
  sortedBy,
}: DataTableInscriptionsProps<TData, TValue>) {
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const matchRoute = useMatchRoute();

  const [switchState, setSwitchState] = useState(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    matchRoute({ to: '/admin/inscriptions/done' })
      ? {
          id: 'isRecordValidated',
          value: switchState,
        }
      : {},
  ]);
  const [columnVisibility, setColumnVisibility] = useState({
    inscriptionID: false,
    stripeID: false,
    deposit: false,
    isRecordValidated: false,
    paidAt: matchRoute({ to: '/admin/inscriptions/done' }) ? true : false,
  });
  const [sorting] = useState<SortingState>(sortedBy);

  useEffect(() => {
    const handleResize = () => {
      if (screenSize.width <= 768) {
        // Par exemple, cacher les colonnes si la largeur de l'écran est inférieure à 768px
        setColumnVisibility((prev) => ({
          ...prev,
          cost: false,
          realCost: false,
          paid: false,
          deposit: false,
        }));
      } else {
        setColumnVisibility((prev) => ({
          ...prev,
          cost: true,
          realCost: true,
          paid: true,
          deposit: true,
        }));
      }
    };

    handleResize(); // Appeler une fois pour définir l'état initial

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [screenSize.width]);

  const table = useReactTable({
    data,
    columns,
    //debugTable: import.meta.env.MODE === 'development',
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //onSortingChange: setSorting,
    initialState: {
      columnVisibility: {
        inscriptionID: false,
        stripeID: false,
        deposit: false,
        isRecordValidated: false,
      },
      pagination: {
        pageSize: defaultRowSize,
        pageIndex: 0,
      },
    },
    state: {
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <div className="w-full space-y-4 py-4">
      <div className="flex flex-col items-center rounded-sm border bg-white p-4 [&>*]:mb-2">
        <div className="flex w-full flex-col lg:flex-row">
          <Input
            placeholder="Chercher un nom, prénom"
            value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
            onChange={(event) =>
              table.getColumn('name')?.setFilterValue(event.target.value)
            }
            className="max-w-1/2 mx-2 my-1 bg-white  lg:my-auto"
          />
          <Input
            placeholder="Chercher un ID Stripe"
            value={(table.getColumn('stripeID')?.getFilterValue() as string) ?? ''}
            onChange={(event) =>
              table.getColumn('stripeID')?.setFilterValue(event.target.value)
            }
            className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto"
          />
        </div>
        <div className="flex w-full flex-col lg:flex-row">
          <Select
            value={(table.getColumn('formuleDate')?.getFilterValue() as string) ?? 'all'}
            onValueChange={(value) => {
              if (value === 'all') {
                table.getColumn('formuleDate')?.setFilterValue('');
              } else {
                table.getColumn('formuleDate')?.setFilterValue(value);
              }
            }}
            defaultValue="all"
          >
            <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
              <SelectValue placeholder="Selectionner une année scolaire" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Toutes les années scolaires</SelectItem>
              {yearList.map((year) => (
                <SelectItem key={year} value={year}>
                  {year}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            value={(table.getColumn('formuleTitle')?.getFilterValue() as string) ?? 'all'}
            onValueChange={(value) => {
              if (value === 'all') {
                table.getColumn('formuleTitle')?.setFilterValue('');
              } else {
                table.getColumn('formuleTitle')?.setFilterValue(value);
              }
            }}
            defaultValue="all"
          >
            <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
              <SelectValue placeholder="Selectionner une année scolaire" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Toutes les formules</SelectItem>
              {formuleList.map((formule) => (
                <SelectItem key={formule} value={formule}>
                  {formule}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {recordValidatedSelector && (
            <div className="mx-2 my-1 flex w-full items-center space-x-2 bg-white lg:my-auto">
              <Switch
                id="record-validated"
                className="data-[state=checked]:bg-green-600 data-[state=unchecked]:bg-gray-200"
                onCheckedChange={(value) => {
                  if (value) {
                    table.getColumn('isRecordValidated')?.setFilterValue(true);
                  } else {
                    table.getColumn('isRecordValidated')?.setFilterValue(false);
                  }
                  setSwitchState(value);
                }}
                checked={
                  (table.getColumn('isRecordValidated')?.getFilterValue() as boolean) ??
                  false
                }
              />
              <Label htmlFor="record-validated">Inscriptions validées uniquement</Label>
            </div>
          )}
        </div>
      </div>
      <div className="rounded-md border bg-white">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() =>
                    navigate({
                      to: '/admin/inscriptions/$inscriptionId',
                      params: {
                        inscriptionId: data[row.id].id as string,
                      },
                    })
                  }
                  className="cursor-pointer transition-colors duration-200 ease-in-out hover:bg-gray-100"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  Pas de résultats
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

export function FormulesDataTable<TData, TValue>({
  columns,
  data,
  yearList,
  formuleList,
}: DataTableProps<TData, TValue>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [openedInformationModal, setOpenedInformationModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<Formule | null>(null);

  const table = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnVisibility: {
        inscriptionID: false,
        doesExpire: false,
      },
      pagination: {
        pageSize: defaultRowSize,
        pageIndex: 0,
      },
    },
    state: {
      columnFilters,
    },
  });

  return (
    <div className="w-full space-y-4 py-4">
      <div className="flex flex-col items-center rounded-sm border bg-white p-4 [&>*]:mb-2">
        <div className="flex w-full flex-col lg:flex-row">
          <Input
            placeholder="Chercher un titre de formule"
            value={(table.getColumn('title')?.getFilterValue() as string) ?? ''}
            onChange={(event) =>
              table.getColumn('title')?.setFilterValue(event.target.value)
            }
            className="max-w-1/2 mx-2 my-1 bg-white  lg:my-auto"
          />
        </div>
        <div className="flex w-full flex-col lg:flex-row">
          <Select
            value={(table.getColumn('year')?.getFilterValue() as string) ?? 'all'}
            onValueChange={(value) => {
              if (value === 'all') {
                table.getColumn('year')?.setFilterValue('');
              } else {
                table.getColumn('year')?.setFilterValue(value);
              }
            }}
            defaultValue="all"
          >
            <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
              <SelectValue placeholder="Selectionner une année scolaire" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Toutes les années scolaires</SelectItem>
              {yearList.map((year) => (
                <SelectItem key={year} value={year}>
                  {year}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            value={(table.getColumn('type')?.getFilterValue() as string) ?? 'all'}
            onValueChange={(value) => {
              if (value === 'all') {
                table.getColumn('type')?.setFilterValue('');
              } else {
                table.getColumn('type')?.setFilterValue(value);
              }
            }}
            defaultValue="all"
          >
            <SelectTrigger className="max-w-1/2 mx-2 my-1 bg-white lg:my-auto">
              <SelectValue placeholder="Selectionner une année scolaire" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Toutes les formules</SelectItem>
              {formuleList.map((formule) => (
                <SelectItem key={formule} value={formule}>
                  {formule}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="rounded-md border bg-white">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => {
                    // @ts-expect-error -- Find a better way to type this
                    setSelectedRowData(data[row.id] as Formule);
                    setOpenedInformationModal(true);
                  }}
                  className="cursor-pointer transition-colors duration-200 ease-in-out hover:bg-gray-100"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  Pas de résultats
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
      <Dialog
        open={openedInformationModal}
        onOpenChange={(value) => {
          setOpenedInformationModal(value);
        }}
      >
        <FormulesInformations formule={selectedRowData as Formule} />
      </Dialog>
    </div>
  );
}

const FormulesInformations = ({ formule }: { formule: Formule }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoader = useCallback(() => {
    setIsLoading((prevLoading) => !prevLoading);
  }, []);

  if (!formule) return null;

  return (
    <DialogContent className="flex flex-col !rounded-sm lg:max-w-[45%]">
      <DialogHeader>
        <DialogTitle className="text-2xl text-previsionblue">{formule.title}</DialogTitle>
        <DialogDescription className="text-lg">
          {formule.year} - {formule.type}
        </DialogDescription>
      </DialogHeader>
      <div className="flex flex-col space-y-1 text-center lg:text-left">
        <div className="flex space-x-4">
          <p>
            <span className="mr-2 text-muted-foreground">Prix :</span>
            {formule.price} €
          </p>
          <p className="text-muted-foreground">&#x2022;</p>
          <p>
            <span className="mr-2 text-muted-foreground">Réduction :</span>
            {formule.reduction} €
          </p>
          {formule.position && (
            <>
              <p className="text-muted-foreground">&#x2022;</p>
              <p>
                <span className="mr-2 text-muted-foreground">Position :</span>
                {formule.position}
              </p>
            </>
          )}
        </div>
        <p className="text-left text-muted-foreground">Description :</p>
        <div className="prose prose-sm prose-stone max-h-52 w-11/12 overflow-auto text-balance break-words px-1.5 text-left prose-h2:my-0 prose-h2:py-0 prose-p:m-0 prose-p:p-0 prose-blockquote:my-0 prose-blockquote:py-0 prose-ol:my-0 prose-ol:py-0 prose-ul:my-0 prose-ul:py-0 prose-li:my-0 prose-li:py-0">
          {parse(formule?.description ?? '')}
        </div>
      </div>
      <div className="flex flex-col justify-between space-y-2 lg:flex-row lg:space-y-0">
        <Button
          className="my-auto min-w-32"
          variant="dashboardOrange"
          onClick={() => {
            if (!formule.id) return;
            toggleLoader();
            modifyCall(formule.id, navigate).then(() => toggleLoader());
          }}
          disabled={isLoading}
        >
          {isLoading ? <LoaderCircle className="animate-spin" /> : 'Modifier'}
        </Button>
        <Button
          className="my-auto w-full min-w-32 sm:w-auto"
          variant="dashboardYellow"
          onClick={() => {
            if (!formule.id) return;
            toggleLoader();
            formule.status === 'archived'
              ? archiveCall(formule.id, 'unpublished', navigate).then(() =>
                  toggleLoader(),
                )
              : archiveCall(formule.id, 'archived', navigate).then(() => toggleLoader());
          }}
          disabled={isLoading}
        >
          {isLoading && <LoaderCircle className="animate-spin" />}
          {!isLoading && (formule.status === 'archived' ? 'Désarchiver' : 'Archiver')}
        </Button>
        {formule.status !== 'archived' && (
          <Button
            className="my-auto w-full min-w-32 sm:w-auto"
            variant={'dashboardGreen'}
            onClick={() => {
              if (!formule.id) return;
              toggleLoader();
              formule.status === 'published'
                ? publishCall(formule.id, 'unpublished', navigate).then(() =>
                    toggleLoader(),
                  )
                : publishCall(formule.id, 'published', navigate).then(() =>
                    toggleLoader(),
                  );
            }}
            disabled={isLoading}
          >
            {isLoading && <LoaderCircle className="animate-spin" />}
            {!isLoading && (formule.status === 'published' ? 'Dépublier' : 'Publier')}
          </Button>
        )}
      </div>
    </DialogContent>
  );
};

const modifyCall = (id: string, navigate: UseNavigateResult<string>) => {
  return navigate({
    to: `/admin/formules/edit`,
    search: {
      id,
    },
  });
};

const publishCall = async (
  id: string,
  status: 'published' | 'unpublished',
  navigate: UseNavigateResult<string>,
) => {
  const docRef = doc(db, 'formules', id);
  try {
    await updateDoc(docRef, {
      status,
    });

    return navigate({
      to: `/admin/formules/${status}`,
    });
  } catch (e) {
    console.error(e);
  }
};

const archiveCall = async (
  id: string,
  status: 'archived' | 'unpublished',
  navigate: UseNavigateResult<string>,
) => {
  const docRef = doc(db, 'formules', id);
  try {
    await updateDoc(docRef, {
      status,
    });
    return navigate({
      to: `/admin/formules/${status}`,
    });
  } catch (e) {
    console.error(e);
  }
};
