import { signInAnonymously, User } from 'firebase/auth';

import { auth } from '@/lib/firebase';

export const getAnonUser = async () => {
  await auth.authStateReady();
  if (auth.currentUser) return auth.currentUser;

  const user = await signInAnonymously(auth);
  return user.user;
};

export const isAdminUser = async (user: User) => {
  await auth.authStateReady();
  const isAnonymous = user.isAnonymous;
  return !isAnonymous;
};
