import { format, isValid, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { useCallback, useState } from 'react';
import { SelectSingleEventHandler } from 'react-day-picker';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';

export const DatePicker = ({
  date,
  setDate,
  initialFocus = false,
}: {
  date: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Find a better type for setDate
  setDate: any;
  initialFocus?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(format(date, 'dd/MM/yyyy') ?? '');
  //const [date, setDate] = React.useState<Date>();
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'dd/MM/yyyy', new Date());
    if (isValid(date)) {
      setDate(date);
    } else {
      setDate(undefined);
    }
  };

  const handleSelectDate: SelectSingleEventHandler = useCallback((selected) => {
    setDate(selected);
    if (selected) {
      setOpen(false);
      setInputValue(format(selected, 'dd/MM/yyyy'));
    } else {
      setInputValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Custom component derived from shadcnUI
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <fieldset className="relative">
        <Input
          placeholder="JJ/MM/AAAA"
          value={inputValue}
          onChange={handleInputChange}
          className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
        />
        <PopoverTrigger asChild>
          <Button
            aria-label="JJ/MM/AAAA"
            variant={'secondary'}
            className={cn(
              'absolute right-1.5 top-1/2 h-7 -translate-y-1/2 rounded-sm border px-2 font-normal',
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
      </fieldset>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleSelectDate}
          initialFocus={initialFocus}
          disabled={(date) => date > new Date() || date < new Date('1900-01-01')}
          locale={fr}
        />
      </PopoverContent>
    </Popover>
  );
};
