import { httpsCallable } from 'firebase/functions';
import { toast } from 'sonner';

import { functions } from '@/lib/firebase';

export const downloadCertificate = async (
  username: string,
  schoolYears: string,
  civility: string,
) => {
  try {
    const createCertificate = httpsCallable(functions, 'downloadCertificatePdf');

    const result = await createCertificate({ username, schoolYears, civility });

    if (result.data === undefined) {
      throw new Error('No data returned');
    }

    const pdfBase64 = result.data;
    const pdfBytes = Uint8Array.from(atob(pdfBase64 as string), (char) =>
      char.charCodeAt(0),
    );

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const url = window.URL.createObjectURL(blob);
    toast('Le certificat a été généré avec succès');

    const usernameCleaned = username.replace(/ /g, '_');
    const schoolYearsCleaned = schoolYears.replace('/', '-');

    // Créer un lien temporaire et déclencher un clic dessus pour télécharger le fichier
    const link = document.createElement('a');
    link.href = url;
    link.download = `prevision_certificat_${schoolYearsCleaned}_${usernameCleaned}.pdf`; // Vous pouvez modifier le nom du fichier ici
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);

    return;
  } catch (error) {
    console.error('Error while downloading certificate:', error);
    toast("Une erreur s'est produite lors de la génération du certificat");
    throw new Error('Error while downloading certificate');
  }
};
