import { createFileRoute } from '@tanstack/react-router';

import { NotFoundPage } from '@/pages/404';
import { InscriptionsTablePage } from '@/pages/admin/inscriptions/tablePage';
import { LoaderPage } from '@/pages/loader';
import { inscriptionsBuilder } from '@/utils/dataBuilder';

export const Route = createFileRoute('/admin/inscriptions/pending')({
  loader: async () => {
    const datas = await inscriptionsBuilder('pending', 'createdAt', 'desc');
    return datas;
  },
  pendingComponent: () => <LoaderPage />,
  component: () => (
    <InscriptionsTablePage
      from="/admin/inscriptions/pending"
      title="Inscriptions en attente"
    />
  ),
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
