import type {} from '@redux-devtools/extension';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { FormuleChoice } from '@/types/types';
import { FORMULE_INFORMATIONS_KEY } from '@/utils/constants';

interface BearState {
  chosenFormule: FormuleChoice | null;
  addFormule: (formule: FormuleChoice) => void;
}

export const useInscriptionsStore = create<BearState>()(
  devtools(
    (set) => ({
      chosenFormule: null,
      addFormule: (formule: FormuleChoice) => set({ chosenFormule: formule }),
    }),
    {
      name: FORMULE_INFORMATIONS_KEY,
    },
  ),
);
