import { createRootRoute, Outlet, redirect } from '@tanstack/react-router';
import ls from 'localstorage-slim';
import { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Toaster } from '@/components/ui/sonner';
import { TanStackRouterDevtools } from '@/lib/utils';
import { NotFoundPage } from '@/pages/404';
import { SchoolRecord } from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';
import { positionFinder } from '@/utils/positionFinder';

export const Route = createRootRoute({
  beforeLoad: ({ location }) => {
    const pendingInscription: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY);

    if (location.pathname === '/') {
      const path = positionFinder(pendingInscription);
      //@ts-expect-error -- Tanstack router dosent like untyped stuff
      throw redirect({ to: path });
    }
  },
  component: () => (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{'Inscription prévision'}</title>
          <meta name="description" content={'Inscription prévision'} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="none" />
          <link rel="icon" href="/favicon.png" />
        </Helmet>
        <Outlet />
        <Toaster />
      </HelmetProvider>
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
  notFoundComponent: () => <NotFoundPage />,
});
