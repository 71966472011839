import '@/assets/styles/index.css';

import * as Sentry from '@sentry/react';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { SENTRY_ENV } from './sentryenvs/sentryenv';

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const SENTRY_DSN =
  'dcee9da5b7338cd70442d50a12ac56ac@o514708.ingest.us.sentry.io/4507669194997760';
const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
const sentryDsnUrl = protocol + SENTRY_DSN;

Sentry.init({
  environment: SENTRY_ENV ?? 'production',
  dsn: sentryDsnUrl,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1.0, // Capture 50% of the traces in production
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['*'],
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.05 : 1.0, // Capture 10% of the sessions in production
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.1, // Capture 50% of the errors in production
});

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}
