import 'react-quill-new/dist/quill.snow.css';

import { QuillOptions } from 'quill';
import ReactQuill from 'react-quill-new';

export const CustomTextEditor = ({
  value,
  setValue,
  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  },
}: {
  value: string;
  setValue: (value: string) => void;
  modules?: QuillOptions['modules'];
}) => {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={setValue}
      className="h-full overflow-y-auto rounded-sm border border-gray-300 bg-white p-2 [&>.ql-container]:h-[80%]"
      modules={modules}
    />
  );
};
