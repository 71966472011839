import { cn } from '@/lib/utils';

export const SectionTitle = ({
  title,
  alternate = null,
  className = '',
  alternateClassName = '',
}: {
  title: string;
  alternate?: string | null;
  className?: string;
  alternateClassName?: string;
}) => {
  return (
    <h2 className={cn('py-2 text-2xl font-bold text-previsionblue', className)}>
      {title} {alternate && <span className={alternateClassName}>{alternate}</span>}
    </h2>
  );
};

export const SectionSubtitle = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}) => {
  return (
    <h3 className={cn('py-5 text-xl font-bold text-previsionblue', className)}>
      {title}
    </h3>
  );
};
