import { createFileRoute } from '@tanstack/react-router';
import { collection, doc, getDoc } from 'firebase/firestore';

import { getScholarYear, getScolarityTypes } from '@/data/getters/admin';
import { db } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { AddFormulePage } from '@/pages/admin/formules/new';
import { LoaderPage } from '@/pages/loader';
import { FirebaseCombobox, Formule } from '@/types/types';

export const Route = createFileRoute('/admin/formules/edit')({
  validateSearch: (search) =>
    search as {
      id: string;
    },
  loaderDeps: ({ search: { id } }) => ({
    id,
  }),
  loader: async ({ deps: { id } }) => {
    let scolarityTypes: FirebaseCombobox | [] = await getScolarityTypes(true);
    if (!scolarityTypes) scolarityTypes = [];

    let schoolYears: FirebaseCombobox | [] = await getScholarYear(true);
    if (!schoolYears) schoolYears = [];

    const colRef = collection(db, 'formules');
    const docRef = doc(colRef, id);

    const data = await getDoc(docRef);
    const formuleData: Formule | object =
      {
        id: data.id,
        ...data.data(),
      } || {};

    return { scolarityTypes, schoolYears, formuleData };
  },
  pendingComponent: () => <LoaderPage />,
  component: () => <AddFormulePage from="/admin/formules/edit" />,
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
