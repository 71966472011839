import { createFileRoute } from '@tanstack/react-router';

import { getScholarYear, getScolarityTypes } from '@/data/getters/admin';
import { NotFoundPage } from '@/pages/404';
import { AddFormulePage } from '@/pages/admin/formules/new';
import { LoaderPage } from '@/pages/loader';
import { FirebaseCombobox } from '@/types/types';

export const Route = createFileRoute('/admin/formules/new')({
  loader: async () => {
    let scolarityTypes: FirebaseCombobox | [] = await getScolarityTypes(true);
    if (!scolarityTypes) scolarityTypes = [];

    let schoolYears: FirebaseCombobox | [] = await getScholarYear(true);
    if (!schoolYears) schoolYears = [];

    return { scolarityTypes, schoolYears };
  },
  pendingComponent: () => <LoaderPage bgClassName="h-screen w-full" />,
  component: () => <AddFormulePage from="/admin/formules/new" />,
  notFoundComponent: () => (
    <NotFoundPage
      primaryColor="previsionorange"
      displayLogo={false}
      buttonText="Retour a l'accueil admin"
      buttonLink="/admin"
      description="Le site a rencontré une erreur."
      isFullScreen={false}
    />
  ),
  gcTime: 0,
  shouldReload: false,
});
