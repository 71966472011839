import { Link } from '@tanstack/react-router';
import clsx from 'clsx';

import previsionLogo from '@/assets/images/prevision-logo.png';
import { Button } from '@/components/ui/button';

export const NotFoundPage = ({
  primaryColor = 'previsionblue',
  title = '404',
  description = 'Le site a rencontré une erreur. Veuillez réessayer plus tard.',
  buttonText = "Retour à l'accueil",
  buttonLink = '/',
  displayLogo = true,
  isFullScreen = true,
  isOnTop = false,
  displayAdvices = false,
  resetHeader = false,
  buttonFunction,
}: {
  primaryColor?: string;
  title?: string | null;
  description?: string;
  buttonText?: string;
  buttonLink?: string;
  displayLogo?: boolean;
  isFullScreen?: boolean;
  isOnTop?: boolean;
  displayAdvices?: boolean;
  resetHeader?: boolean;
  buttonFunction?: () => void;
}) => {
  return (
    <section
      className={clsx(
        'flex w-full flex-col items-center justify-center space-y-4',
        isFullScreen ? 'h-screen' : 'h-auto',
        isOnTop && 'absolute z-50 bg-previsiongrey',
        resetHeader && 'mt-[-6rem]',
      )}
    >
      {displayLogo && <img src={previsionLogo} alt="404" className="max-w-80" />}
      {title && (
        <h1
          className={clsx(
            'text-center text-6xl font-bold',
            primaryColor != 'previsionblue'
              ? `text-${primaryColor}`
              : 'text-previsionblue',
          )}
        >
          {title}
        </h1>
      )}
      <p
        className={clsx(
          'text-center text-xl font-bold',
          primaryColor != 'previsionblue' ? `text-${primaryColor}` : 'text-previsionblue',
        )}
      >
        {description}
      </p>
      {displayAdvices && (
        <div>
          <p className="text-center text-lg font-bold text-previsionblue">
            Merci d'essayer ces étapes :
          </p>
          <ul className="mx-8 space-y-1">
            <li className="text-left font-medium text-previsionblue">
              Désactiver vos bloqueurs de publicité.
            </li>
            <li className="text-left font-medium text-previsionblue">
              Réessayer sur un autre navigateur internet.
            </li>
            <li className="text-left font-medium text-previsionblue">
              Réessayer sur un autre appareil.
            </li>
            <li className="text-left font-medium text-previsionblue">
              Vérifier votre connexion internet.
            </li>
          </ul>
          <br />
          <p className="text-center text-lg font-bold text-previsionblue">
            Si le problème persiste,
            <br />
            contactez-nous sur{' '}
            <a
              href="mailto:contact@prepa-prevision.fr,dest.stroyco+support-prevision-platform@gmail.com"
              className="text-previsionblue underline"
            >
              contact@prepa-prevision.fr
            </a>
          </p>
        </div>
      )}
      <Button
        variant="squaredBlue"
        className={clsx(
          primaryColor != 'previsionblue' &&
            `bg-${primaryColor} hover:bg-${primaryColor} hover:opacity-65`,
        )}
        onClick={buttonFunction ? buttonFunction : undefined}
        asChild
      >
        {/* @ts-expect-error --- Router type does not like untyped string */}
        <Link to={buttonLink}>{buttonText}</Link>
      </Button>
    </section>
  );
};
