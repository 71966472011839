import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { updateRecordNote } from '@/data/form-handlers';
import { SchoolRecordEnforced } from '@/types/types';

export const RecordNoteBlock = ({
  previousNote,
  record,
}: {
  previousNote: string;
  record: SchoolRecordEnforced;
}) => {
  const [notes, setNotes] = useState(previousNote ?? '');

  const saveNotes = async () => {
    const res = await updateRecordNote(record, notes);

    if (res) {
      toast('Note enregistrées avec succès');
    } else {
      toast("Erreur lors de l'enregistrement de la note");
    }
  };

  useEffect(() => {}, [previousNote]);

  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <h3 className="text-xl font-bold text-previsionblue">NOTE</h3>
      <Textarea
        className="min-h-36 resize-none"
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
      />
      <Button variant="dashboardBlue" className="w-full" onClick={saveNotes}>
        Enregistrer
      </Button>
    </div>
  );
};
