import { SchoolRecordEnforced } from '@/types/types';

export interface TimelineType {
  order: number;
  event: string;
  date: Date;
  description: string;
}

export const buildTimeline = (data: SchoolRecordEnforced): Array<TimelineType> => {
  const timeline: Array<TimelineType> = [];

  if (data.createdAt) {
    timeline.push({
      order: 1,
      event: 'Création',
      // @ts-expect-error --- Correct type is Timestamp (Firestore)
      date: data.createdAt.toDate(),
      description: "L'élève a débuté son inscription.",
    });
  }

  if (data.payments && data.payments.schedule) {
    const depositDetails = data.payments.schedule.find(
      (schedule) => schedule.paymentType === 'accompte',
    );
    if (
      depositDetails &&
      depositDetails.paymentDate &&
      depositDetails.paymentStatus === 'paid'
    ) {
      timeline.push({
        order: 2,
        event: 'Paiement',
        // @ts-expect-error --- Correct type is Timestamp (Firestore)
        date: depositDetails.paymentDate.toDate(),
        description: `L'élève s'est acquitté de l'acompte via ${depositDetails.paymentMethod}.`,
      });
    }
  }

  return timeline;
};
