'use client';

import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { SquareCheck, SquareMinus } from 'lucide-react';

import { Badge } from '@/components/ui/badge';
import { Formule, InscriptionRow } from '@/types/types';

export const inscriptionsColumn: ColumnDef<InscriptionRow>[] = [
  {
    accessorKey: 'inscriptionID',
    header: "ID de l'inscription",
  },
  {
    accessorKey: 'name',
    header: 'Nom - Prénom',
  },
  {
    accessorKey: 'createdAt',
    header: 'Date de l’inscription',
    cell: ({ row }) => {
      const value = row.getValue('createdAt');
      if (!value || value === null) return <p>Indisponible</p>;

      return <p>{formatDate(value as Date, 'dd/MM/yyyy')}</p>;
    },
  },
  {
    accessorKey: 'paidAt',
    header: 'Acompte réglé le',
    cell: ({ row }) => {
      const value = row.getValue('paidAt') as Timestamp;
      if (!value || value === null) return <p>Indisponible</p>;

      return <p>{formatDate(value.toDate(), 'dd/MM/yyyy')}</p>;
    },
  },
  {
    accessorKey: 'formuleDate',
    header: 'Année scolaire',
  },
  {
    accessorKey: 'formuleTitle',
    header: 'Formule',
  },
  {
    accessorKey: 'cost',
    header: 'Montant de la formation',
    cell: ({ row }) => {
      const value = row.getValue('cost');
      if (value === 0) return <p>Non renseigné</p>;

      // @ts-expect-error -- We know value is a number
      return <p>{value} €</p>;
    },
  },
  {
    accessorKey: 'realCost',
    header: 'Montant facturé',
    cell: ({ row }) => {
      const value = row.getValue('realCost') as number;

      return <p>{value / 100} €</p>;
    },
  },
  {
    accessorKey: 'paid',
    header: 'Montant déja payé',
    cell: ({ row }) => {
      const value = row.getValue('paid');
      if (value === 0) return <p>Non renseigné</p>;

      // @ts-expect-error -- We know value is a number
      return <p>{value / 100} €</p>;
    },
  },
  {
    accessorKey: 'deposit',
    header: 'Acompte',
    cell: ({ row }) => {
      const value = row.getValue('deposit');
      if (value === 0) return <p>Non renseigné</p>;

      // @ts-expect-error -- We know value is a number
      return <p>{value} €</p>;
    },
  },
  {
    accessorKey: 'paymentStatus',
    header: "Statut du paiement de l'accompte",
    cell: ({ row }) => {
      const value = row.getValue('paymentStatus');

      if (value === 'paid') return <Badge variant="green">Payé</Badge>;
      if (value === 'refunded') return <Badge variant="red">Remboursée</Badge>;
      return <Badge variant="red">En attente</Badge>;
    },
  },
  {
    accessorKey: 'fileStatus',
    header: 'Bulletin(s) déposé(s)',
    cell: ({ row }) => {
      const value: boolean[][] = row.getValue('fileStatus');

      if (
        value === null ||
        value === undefined ||
        value.length === 0 ||
        value[0]?.length === 0
      )
        return <p>Non renseigné</p>;

      return (
        <div>
          {value.map((array: boolean[], indexOne: number) => {
            return (
              <div key={indexOne} className="flex">
                <div className="flex justify-between gap-x-1">
                  <p className="text-[0.9em] font-medium underline">
                    {indexOne === 0 ? '1ere:' : 'Ter :'}
                  </p>
                  {array.map((bool: boolean, indexTwo: number) => {
                    return (
                      <p className="flex justify-start gap-x-1 text-[1em]" key={indexTwo}>
                        {indexTwo + 1}
                        {bool ? (
                          <SquareCheck className="my-auto text-green-600" size={15} />
                        ) : (
                          <SquareMinus className="my-auto text-red-600" size={15} />
                        )}
                        {indexTwo === array.length - 1 ? '' : '|'}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    accessorKey: 'stripeID',
    header: 'Stripe ID',
  },
  {
    accessorKey: 'isRecordValidated',
    header: "Statut de l'inscription",
  },
];

export const formulesColumn: ColumnDef<Formule>[] = [
  {
    accessorKey: 'title',
    header: 'Titre de la formule',
  },
  {
    accessorKey: 'year',
    header: 'Année de la formule',
  },
  {
    accessorKey: 'type',
    header: 'Type de la formule',
  },
  {
    accessorKey: 'position',
    header: 'Position de la formule',
    cell: ({ row }) => {
      const value = row.getValue('position');
      if (
        value === null ||
        value === undefined ||
        value === 0 ||
        value === '0' ||
        value === ''
      )
        return <p></p>;

      // @ts-expect-error -- We know value is a number
      return <p>{value}</p>;
    },
  },
  {
    accessorKey: 'price',
    header: 'Coût de la formule',
    cell: ({ row }) => {
      const value = row.getValue('price');
      if (value === 0) return <p>Non renseigné</p>;

      // @ts-expect-error -- We know value is a number
      return <p>{value} €</p>;
    },
  },
  {
    accessorKey: 'doesExpire',
    header: 'Expire ?',
  },
];
