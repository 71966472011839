import { zodResolver } from '@hookform/resolvers/zod';
import { useLoaderData, useMatchRoute, useNavigate } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { Combobox, ComboboxOptions } from '@/components/combobox';
import { CustomTextEditor } from '@/components/text-editor/Editor';
import { SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { addFormuleSchema } from '@/data/forms-schema';
import { addScholarityType, addYear, saveFormule } from '@/data/setters/admin';
import { saveDocumentToFirebase } from '@/data/utils';
import { FirebaseCombobox, Formule } from '@/types/types';
import { correctULTagFromQuill } from '@/utils/quill';

const editorModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ color: [] }, { background: [] }],
    ['clean'],
  ],
};

export const AddFormulePage = ({ from }: { from: string }) => {
  const navigate = useNavigate();

  const {
    schoolYears,
    scolarityTypes,
    formuleData,
  }: {
    schoolYears: FirebaseCombobox[];
    scolarityTypes: FirebaseCombobox[];
    formuleData: Formule;
  } = useLoaderData({
    // @ts-expect-error -- Type is coming from router, find how to type it
    from,
  });

  const matchRoute = useMatchRoute();
  const isEditPage = matchRoute({ to: '/admin/formules/edit' }) ? true : false;

  const currentYear = new Date().getFullYear();
  let closestYearDiff = Infinity;
  let closestYearItem = null;
  schoolYears.forEach((year) => {
    const yearStart = parseInt(year.value.slice(0, 4));
    const diff = Math.abs(currentYear - yearStart);

    if (diff < closestYearDiff) {
      closestYearDiff = diff;
      closestYearItem = year;
    }
  });
  if (!closestYearItem && schoolYears.length > 0) {
    if (schoolYears[0]) {
      closestYearItem = { value: schoolYears[0].value, label: schoolYears[0].label };
    }
  }
  const schoolYearDefault = closestYearItem
    ? closestYearItem.value
    : schoolYears[0]
      ? schoolYears[0].value
      : null;

  const [schoolYearList, setSchoolYearList] = useState<ComboboxOptions[]>(schoolYears);
  const [courseTypeList, setCourseTypeList] = useState<ComboboxOptions[]>(scolarityTypes);

  const [editorValue, setEditorValue] = useState<string>(formuleData?.description ?? '');

  const form = useForm<z.infer<typeof addFormuleSchema>>({
    resolver: zodResolver(addFormuleSchema),
    defaultValues: {
      schoolYear: formuleData?.year ?? schoolYearDefault,
      courseType: formuleData?.type ?? '',
      courseTitle: formuleData?.title ?? '',
      coursePrice: formuleData?.price ?? '0',
      courseReduction: formuleData?.reduction ?? '0',
      courseDoesExpire: formuleData?.expirationDate ? true : false,
      courseExpirationDate: formuleData?.expirationDate ?? '',
      position: formuleData?.position ?? '',
    },
  });

  return (
    <section className="w-full bg-previsiongrey p-2 md:w-[calc(100%-20rem)]">
      <SectionTitle
        title={isEditPage ? 'Modifier la formule:' : 'Ajouter une formule'}
        alternate={formuleData && `${formuleData.title && formuleData.title}`}
        alternateClassName="italic opacity-85"
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            //If is missing data from the form, return
            if (
              !data.schoolYear ||
              !data.courseType ||
              !data.courseTitle ||
              !editorValue ||
              !data.coursePrice ||
              !data.courseReduction
            ) {
              toast('Merci de renseigner tous les champs');
              return;
            }

            let editorValueCleaned = editorValue;
            editorValueCleaned = correctULTagFromQuill(editorValueCleaned);

            try {
              if (isEditPage) {
                if (!formuleData || !formuleData.id) {
                  toast('Erreur lors de la mise à jour de la formule');
                  return;
                }

                await addYear(data.schoolYear, null, false);
                await addScholarityType(data.courseType, null, false);

                const updatedFormule: Omit<Formule, 'id'> = {
                  status: formuleData.status,
                  year: data.schoolYear,
                  type: data.courseType,
                  title: data.courseTitle,
                  description: editorValueCleaned,
                  price: data.coursePrice,
                  reduction: data.courseReduction,
                  doesExpire: data.courseDoesExpire,
                  expirationDate: data.courseExpirationDate || null,
                  position: data.position || null,
                };

                await saveDocumentToFirebase('formules', formuleData.id, updatedFormule);

                //TODO: Create a cloud function to update all the inscriptions with the new formule data
              } else {
                await saveFormule({
                  ...data,
                  courseDescription: editorValueCleaned,
                });
              }

              form.reset();

              toast('Formule créée avec succès', {
                description: 'Voulez-vous accéder à la liste des formules ?',
                action: {
                  label: 'Y aller',
                  onClick: () =>
                    navigate({
                      to: `/admin/formules/${formuleData && formuleData.status ? formuleData.status : 'unpublished'}`,
                    }),
                },
              });
            } catch (error) {
              console.error('Error adding document: ', error);
              toast('Erreur lors de la création de la formule');
            }
          })}
          className="py-6"
        >
          <div className="flex w-full flex-col lg:flex-row lg:justify-between">
            <FormField
              control={form.control}
              name="schoolYear"
              render={({ field }) => (
                <FormItem className="mx-2 lg:w-1/2">
                  <FormLabel className="font-bold">Années de la formation</FormLabel>
                  <FormControl>
                    <Combobox
                      mode="single"
                      options={schoolYearList}
                      placeholder="sélectionnez ou créez une année"
                      selected={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      onCreate={(value) => {
                        setSchoolYearList((prev) => [...prev, { value, label: value }]);
                        field.onChange(value);
                      }}
                    />
                  </FormControl>
                  <FormDescription className="text-sm font-light">
                    Si l'année est entre deux différentes, merci de renseigner l'année en
                    format "AAAA/AAAA"
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="courseType"
              render={({ field }) => (
                <FormItem className="mx-2 lg:w-1/2">
                  <FormLabel className="font-bold">Type de formation</FormLabel>
                  <FormControl>
                    <Combobox
                      mode="single"
                      options={courseTypeList}
                      placeholder="sélectionnez ou créez un type de formation"
                      selected={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      onCreate={(value) => {
                        setCourseTypeList((prev) => [...prev, { value, label: value }]);
                        field.onChange(value);
                      }}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mx-2 flex flex-col">
            <FormField
              control={form.control}
              name="courseTitle"
              render={({ field }) => (
                <FormItem className="my-1">
                  <FormLabel className="font-bold">Intitulé de la formation</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="my-1">
              <FormLabel className="font-bold">Description de la formation</FormLabel>
              <div className="h-60">
                <CustomTextEditor
                  value={editorValue}
                  setValue={setEditorValue}
                  modules={editorModules}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
              <FormField
                control={form.control}
                name="coursePrice"
                render={({ field }) => (
                  <FormItem className="my-2">
                    <FormLabel className="font-bold">Prix de la formation (€)</FormLabel>
                    <FormControl>
                      <Input {...field} type="number" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="courseReduction"
                render={({ field }) => (
                  <FormItem className="my-2">
                    <FormLabel className="font-bold">
                      Réduction de la formation (%)
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="number" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="position"
                render={({ field }) => (
                  <FormItem className="my-2">
                    <FormLabel className="font-bold">
                      Ordre d'affichage de la formule
                    </FormLabel>

                    <FormControl>
                      <Input {...field} type="number" min="1" />
                    </FormControl>
                    <FormDescription className="text-sm font-light">
                      De 1 à X, 1 étant la formule affichée la première.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="my-4 flex w-full justify-end">
            <Button
              type="submit"
              variant="dashboardBlue"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting && <LoaderCircle className="animate-spin" />}
              {!form.formState.isSubmitting && isEditPage && 'Modifier la formule'}
              {!form.formState.isSubmitting && !isEditPage && 'Ajouter la formule'}
            </Button>
          </div>
        </form>
      </Form>
    </section>
  );
};
