import { getDownloadURL, ref } from 'firebase/storage';
import { Download, SquarePen } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { storage } from '@/lib/firebase';
import { RecordFile } from '@/types/types';
import { fileTitleConverter } from '@/utils/titleConverter';

export const StudentDocuments = ({
  files,
  triggerEdit,
}: {
  files: RecordFile[];
  triggerEdit: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const filesArray = files ? Object.entries(files) : [];
  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold text-previsionblue">DOCUMENTS</h3>
        <Button
          variant="outline"
          className="w-auto"
          onClick={() => {
            triggerEdit('files');
          }}
        >
          <SquarePen size={24} />
        </Button>
      </div>
      <div className="flex flex-wrap">
        {filesArray.map((file) => {
          return (
            <Button
              key={`${file[1].type}-${file[1].url}`}
              variant="dashboardBlue"
              className="m-1 w-full"
              onClick={async () => {
                const fileRef = ref(storage, file[1].url);
                const blob = await getDownloadURL(fileRef);
                window.open(blob, '_blank');
              }}
            >
              <Download className="mr-2" /> {fileTitleConverter(file[1].type)}
            </Button>
          );
        })}
      </div>
      {filesArray.length === 0 && (
        <div className="text-center">
          <p>Aucun document</p>
          <p>Voulez-vous ajouter un document ?</p>
        </div>
      )}
    </div>
  );
};
