import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useLoaderData, useNavigate } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { stepSixSubmit } from '@/data/form-handlers';
import { termsSchema } from '@/data/forms-schema';
import { SchoolRecord } from '@/types/types';

export const TermsAndConditions = () => {
  const navigate = useNavigate();
  const [whatFile, setWhatFile] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const {
    pendingInscription,
  }: {
    pendingInscription: SchoolRecord | null;
    // @ts-expect-error --  Les types de la propriété strict sont incompatibles.(Impossible d'assigner le type false au type true)
  } = useLoaderData({
    from: '/steps',
    strict: false,
  });

  const { gtcUrl, scholarityRulesUrl, imageUseUrl } = useLoaderData({
    from: '/steps/six',
  });

  const termsAcceptedObject = pendingInscription?.terms?.termsAccepted.reduce(
    (acc, item) => {
      // @ts-expect-error --  Réparer type plus tard
      acc[item.name] = true;
      return acc;
    },
    {},
  );

  const form = useForm<z.infer<typeof termsSchema>>({
    resolver: zodResolver(termsSchema),
    defaultValues: {
      ...termsAcceptedObject,
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const response = await stepSixSubmit(data);
          if (response) {
            navigate({
              to: '/steps/seven',
            });
          } else {
            console.error('Error adding document');
          }
        })}
        className="h-fit bg-white px-7 py-4 text-black/60 lg:w-2/3"
      >
        <SectionTitle title="Conditions générales" />
        <FormDescription className="mb-4">
          Afin de finaliser votre dossier merci de prendre connaissance et d'approuver les
          différents règlements de la prépa.
        </FormDescription>
        <FormField
          control={form.control}
          name="gtcConsent"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-4">
              <FormControl>
                <Checkbox
                  className="rounded-sm  border-black/60 data-[state=checked]:border-previsionorange data-[state=checked]:bg-previsionorange"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-base">
                  L’étudiant et son responsable légal acceptent les{' '}
                  <a
                    className="font-bold text-previsionorange transition-all hover:cursor-pointer hover:opacity-60"
                    onClick={() => {
                      setWhatFile('gtc');
                      setOpenModal(true);
                    }}
                  >
                    conditions générales de ventes
                  </a>
                  .
                </FormLabel>
                <FormMessage className="text-xs italic opacity-75" />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="emailConsent"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-4">
              <FormControl>
                <Checkbox
                  className="rounded-sm  border-black/60 data-[state=checked]:border-previsionorange data-[state=checked]:bg-previsionorange"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-base">
                  L’étudiant et son responsable légal acceptent l’utilisation de leurs
                  courriels.
                </FormLabel>
                <FormMessage className="text-xs italic opacity-75" />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="scholarityRulesConsent"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-4">
              <FormControl>
                <Checkbox
                  className="rounded-sm  border-black/60 data-[state=checked]:border-previsionorange data-[state=checked]:bg-previsionorange"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-base">
                  L’étudiant et son responsable légal s’engagent à respecter le{' '}
                  <a
                    className="font-bold text-previsionorange transition-all hover:cursor-pointer hover:opacity-60"
                    onClick={() => {
                      setWhatFile('scholarityRules');
                      setOpenModal(true);
                    }}
                  >
                    règlement intérieur de l’établissement
                  </a>
                  .
                </FormLabel>
                <FormMessage className="text-xs italic opacity-75" />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="ndaConsent"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-4">
              <FormControl>
                <Checkbox
                  className="rounded-sm  border-black/60 data-[state=checked]:border-previsionorange data-[state=checked]:bg-previsionorange"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-base">
                  L’étudiant s’engage à une utilisation strictement personnelle des
                  ressources pédagogiques fournies par l’école. Ces ressources constituent
                  notre savoir-faire et ne doivent servir qu’à nos étudiants.
                </FormLabel>
                <FormMessage className="text-xs italic opacity-75" />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="imageUseConsent"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-4">
              <FormControl>
                <Checkbox
                  className="rounded-sm  border-black/60 data-[state=checked]:border-previsionorange data-[state=checked]:bg-previsionorange"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-base">
                  L’étudiant et son responsable légal acceptent les{' '}
                  <a
                    className="font-bold text-previsionorange transition-all hover:cursor-pointer hover:opacity-60"
                    onClick={() => {
                      setWhatFile('imageUse');
                      setOpenModal(true);
                    }}
                  >
                    clauses de droit à l’image
                  </a>
                  .
                </FormLabel>
                <FormMessage className="text-xs italic opacity-75" />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="studentLegalResponsibilityWaiver"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-4">
              <FormControl>
                <Checkbox
                  className="rounded-sm border-black/60 data-[state=checked]:border-previsionorange data-[state=checked]:bg-previsionorange"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-base">
                  L’étudiant et son responsable légal déclarent dégagée de toutes
                  responsabilités, la SAS PREVISION sous le nom commercial de PREVISION en
                  cas d’incident de toute nature que ce soit ayant lieu lorsque les
                  étudiants finissent les cours plus tôt et sortent des cours en avance
                  lors de l’année scolaire.
                </FormLabel>
                <FormMessage className="text-xs italic opacity-75" />
              </div>
            </FormItem>
          )}
        />
        <div className="mt-4 flex justify-between">
          <Link to={`/steps/five`}>
            <Button type="button" variant="outlinedBlue" className="w-32">
              Précédent
            </Button>
          </Link>
          <Button
            type="submit"
            variant="squaredBlue"
            className="w-32"
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Suivant'
            )}
          </Button>
        </div>
      </form>
      <Dialog open={openModal} onOpenChange={(open: boolean) => setOpenModal(open)}>
        <DialogContent className="flex h-[80vh] max-w-md flex-col sm:max-w-xl lg:max-w-4xl">
          {whatFile === 'gtc' && <SectionTitle title="Conditions générales de ventes" />}
          {whatFile === 'scholarityRules' && (
            <SectionTitle title="Règlement intérieur de l’établissement" />
          )}
          {whatFile === 'imageUse' && <SectionTitle title="Clauses de droit à l’image" />}

          {whatFile === 'gtc' && (
            <iframe
              title="Conditions générales de ventes - Prevision"
              src={gtcUrl}
              className="h-full w-full"
            />
          )}
          {whatFile === 'scholarityRules' && (
            <iframe
              title="Règlement intérieur de l’établissement - Prevision"
              src={scholarityRulesUrl}
              className="h-full w-full"
            />
          )}
          {whatFile === 'imageUse' && (
            <iframe
              title="Clauses de droit à l’image - Prevision"
              src={imageUseUrl}
              className="h-full w-full"
            />
          )}
        </DialogContent>
      </Dialog>
    </Form>
  );
};
