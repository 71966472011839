import { Link, useLoaderData, useNavigate } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';

import { FormuleSelectCard } from '@/components/formule-cards';
import { SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { stepFourSubmit } from '@/data/form-handlers';
import {
  FirebaseCombobox,
  FormuleForChoice,
  SchoolRecord,
  SortedFormules,
} from '@/types/types';
import { PREVISION_MAIL } from '@/utils/constants';

export const OptionsChoicePage = () => {
  const navigate = useNavigate();

  const {
    cleanedData,
    pendingInscription,
    filters,
  }: {
    cleanedData: SortedFormules;
    pendingInscription: SchoolRecord | null;
    filters: {
      years: FirebaseCombobox[];
      types: FirebaseCombobox[];
    };
  } = useLoaderData({
    from: '/steps/four',
  });

  const baseYear =
    filters && filters.years && filters.years[0] && filters.years.length > 0
      ? filters.years[0].value
      : '';
  const baseType =
    filters && filters.types && filters.types[0] && filters.types.length > 0
      ? filters.types[0].value
      : '';

  const [selectedYear, setSelectedYear] = useState<string>(baseYear);
  const [selectedType, setSelectedType] = useState<string>(baseType);
  const [loaderSelectionHandler, setLoaderSelectionHandler] = useState<boolean>(false);

  const [selectedFormule, setSelectedFormule] = useState<FormuleForChoice | null>(null);

  useEffect(() => {
    if (pendingInscription && pendingInscription.formule) {
      const formules = Object.values(cleanedData).flatMap((year) =>
        Object.values(year).flat(),
      );
      const localStorageFormule = pendingInscription.formule;
      if (localStorageFormule.formuleId) {
        const selectedFormule =
          formules.find((formule) => formule.id === localStorageFormule.formuleId) ||
          null;

        setSelectedFormule(selectedFormule);
      }
    }
  }, [cleanedData, pendingInscription]);

  if (!cleanedData || Object.keys(cleanedData).length === 0)
    return (
      <div className="h-[calc(90dvh-4rem)] w-full bg-white px-4 py-4 text-black/60 md:px-7 lg:w-2/3">
        <SectionTitle title="Choix de ma formule" />
        <p>Choisissez ici le type de préparation que vous souhaitez.</p>
        <div className="m-auto flex h-[50dvh] flex-col justify-center align-middle">
          <p className="text-center italic">Aucune formule disponible</p>
          <p className="text-center">
            Merci de nous contacter à{' '}
            <a
              href={`mailto:${PREVISION_MAIL}`}
              className="font-semibold italic text-previsionorange"
            >
              {PREVISION_MAIL}
            </a>
          </p>
        </div>
      </div>
    );

  return (
    <form
      className="h-fit w-full bg-white px-4 py-4 text-black/60 md:px-7 lg:w-2/3"
      onSubmit={async (e) => {
        e.preventDefault();
        if (!selectedFormule) return console.error('No formule selected');
        const response = await stepFourSubmit(selectedFormule);
        if (response) {
          navigate({
            to: '/steps/five',
          });
        } else {
          console.error('Error adding document');
        }
      }}
    >
      <SectionTitle title="Choix de ma formule" />
      <p>Choisissez ici le type de préparation que vous souhaitez.</p>

      <div className="hidden lg:block">
        <Tabs
          defaultValue={Object.keys(cleanedData)[0] || ''}
          onValueChange={(value) => {
            setLoaderSelectionHandler(true);
            setSelectedYear(value);
            setTimeout(() => {
              if (!cleanedData[value]) return;
              const cleanedDataArray = Object.keys(cleanedData[value]);
              const type = cleanedDataArray[0] || '';
              if (!type) return;
              setSelectedType(type);
              setLoaderSelectionHandler(false);
            }, 500);
          }}
          className="my-4"
        >
          <TabsList className="flex h-auto w-full justify-between">
            {filters.years.map((year, i) => (
              <TabsTrigger
                key={year.id ?? `${i}-year-${year.value}`}
                value={year.value}
                className="w-full whitespace-normal text-lg"
              >
                {year.label}
              </TabsTrigger>
            ))}
          </TabsList>

          {Object.keys(cleanedData).map((year, i) => (
            <TabsContent key={year + i} value={year}>
              <Tabs
                // @ts-expect-error - This can't be undefined
                defaultValue={Object.keys(cleanedData[year])[0] || ''}
                onValueChange={(value) => {
                  let type = value as string;

                  // @ts-expect-error - We know that the year exists
                  if (!cleanedData[selectedYear][type]) {
                    // @ts-expect-error - We know that the year exists
                    type = Object.keys(cleanedData[selectedYear])[0];
                  }

                  setSelectedType(type);
                }}
              >
                <TabsList className="flex h-auto w-full justify-between">
                  {filters.types.map((type, i) => {
                    // @ts-expect-error - We know that the year exists
                    if (!cleanedData[year][type.value]) return null;
                    return (
                      <TabsTrigger
                        key={type.id ?? `${i}-type-${type.value}`}
                        value={type.value}
                        className="w-full whitespace-normal text-base"
                      >
                        {type.label}
                      </TabsTrigger>
                    );
                  })}
                </TabsList>
                {/* @ts-expect-error - We know that the year exists */}
                {Object.keys(cleanedData[year]).map((scolarityType, i2) => {
                  // @ts-expect-error - We know that the year exists
                  if (!cleanedData[year][scolarityType]) return null;
                  // @ts-expect-error - We know that the year exists
                  if (Object.keys(cleanedData[year][scolarityType]).length === 0)
                    return null;

                  return (
                    <TabsContent
                      key={scolarityType + i2}
                      value={scolarityType}
                      className="flex-col gap-4 py-4"
                    >
                      {/* @ts-expect-error - Object cannot be undefined */}
                      {Object.keys(cleanedData[year][scolarityType]).map(
                        (formule: string, i) => {
                          const { title, price, description } =
                            // @ts-expect-error - We know that the formule exists and not a number
                            cleanedData[year][scolarityType][formule];

                          return (
                            <FormuleSelectCard
                              key={formule + i}
                              title={title}
                              price={price}
                              description={description}
                              currentSelectedTitle={selectedFormule?.title || ''}
                              selectFunction={() =>
                                setSelectedFormule(
                                  // @ts-expect-error - Object cannot be undefined
                                  cleanedData[year][scolarityType][ // @ts-expect-error - We know that the formule exists and not a number
                                    formule
                                  ] as FormuleForChoice,
                                )
                              }
                            />
                          );
                        },
                      )}
                    </TabsContent>
                  );
                })}
              </Tabs>
            </TabsContent>
          ))}
        </Tabs>
      </div>
      <div className="my-4 space-y-4 lg:hidden">
        <Select
          value={selectedYear}
          onValueChange={(value) => {
            setLoaderSelectionHandler(true);
            setSelectedYear(value);
            setTimeout(() => {
              // @ts-expect-error - Object cannot be undefined
              setSelectedType(Object.keys(cleanedData[value])[0]);
              setLoaderSelectionHandler(false);
            }, 500);
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Choisissez l'année" />
          </SelectTrigger>
          <SelectContent>
            {filters.years.map((year, i) => (
              <SelectItem key={year.id ?? i} value={year.value}>
                {year.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select
          value={selectedType}
          onValueChange={(value) => {
            let type = value as string;

            // @ts-expect-error - Object cannot be undefined
            if (!cleanedData[selectedYear][type]) {
              // @ts-expect-error - Object cannot be undefined
              type = Object.keys(cleanedData[selectedYear])[0];
            }

            setSelectedType(type);
          }}
          disabled={!selectedYear || loaderSelectionHandler}
        >
          <SelectTrigger>
            <SelectValue placeholder="Choisissez le type de préparation" />
          </SelectTrigger>
          <SelectContent>
            {filters.types.map((type, i) => {
              if (!cleanedData[selectedYear] || !cleanedData[selectedYear][type.value])
                return null;

              return (
                <SelectItem key={type.id ?? i} value={type.value}>
                  {type.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
        <div className="flex flex-col gap-4 py-4">
          {cleanedData[selectedYear] &&
            cleanedData[selectedYear][selectedType] &&
            Object.keys(cleanedData[selectedYear][selectedType]).map(
              (formule: string, i) => {
                const { title, price, description } =
                  // @ts-expect-error - We know that the formule exists and not a number
                  cleanedData[selectedYear][selectedType][formule];

                return (
                  <FormuleSelectCard
                    key={formule + i}
                    title={title}
                    price={price}
                    description={description}
                    currentSelectedTitle={selectedFormule?.title || ''}
                    selectFunction={() =>
                      setSelectedFormule(
                        // @ts-expect-error - Object cannot be undefined
                        cleanedData[selectedYear][selectedType][
                          //We force the type to be a number but it's a string
                          formule as unknown as number
                        ] as FormuleForChoice,
                      )
                    }
                  />
                );
              },
            )}

          {loaderSelectionHandler && (
            <LoaderCircle className="mx-auto animate-spin text-previsionblue" size={32} />
          )}

          {!loaderSelectionHandler &&
            !selectedType &&
            // @ts-expect-error - Object cannot be undefined
            !cleanedData[selectedYear][selectedType] && (
              <p className="text-center">
                Aucune formule disponible pour cette année et ce type de préparation
              </p>
            )}
        </div>
      </div>
      <div className="flex justify-between">
        <Link to={`/steps/three`}>
          <Button type="button" variant="outlinedBlue" className="w-32">
            Précédent
          </Button>
        </Link>
        <Button
          className="w-32"
          type="submit"
          variant="squaredBlue"
          disabled={!selectedFormule}
          onClick={() => {
            if (!selectedFormule) {
              alert('Veuillez sélectionner une formule');
            }

            return;
          }}
        >
          Suivant
        </Button>
      </div>
    </form>
  );
};
