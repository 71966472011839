export const firebaseConfig = {
  apiKey: 'AIzaSyBM6RBlOx1DR63FWfNugq0k8YRW_AwGjZ0',
  authDomain: 'prevision-prepa.firebaseapp.com',
  projectId: 'prevision-prepa',
  storageBucket: 'prevision-prepa.appspot.com',
  messagingSenderId: '450791002356',
  appId: '1:450791002356:web:60ab9ba7ddd6316d0c8ea1',
};

export const functionsURL = 'http://127.0.0.1:5001/prevision-custom/us-central1/';

export const stripePublicKey =
  'pk_live_51OEdUQJqZppZzkFQFbmVTSFHXRu2iLsYD5JX38Mz1lJKCA61udkA6e8aLZkJt3onmlw18qI1ae8mpyPkLeyfDIi600I7EovFO0';

export const DB_NAME = 'new-prevision';

